import React from 'react';
import styled from 'styled-components';
import { ResponsivePie } from '@nivo/pie';

const theme = {
  labels: {
    text: {
      fontSize: 12
    }
  }
};

const Pie = ({ height, data }) => (
  <Div height={height}>
    <ResponsivePie
      data={data}
      margin={{
        top: 50,
        right: 0,
        bottom: 60,
        left: 100
      }}
      innerRadius={0.5}
      padAngle={1.5}
      cornerRadius={3}
      colors="nivo"
      colorBy="id"
      borderWidth={1}
      borderColor="inherit:darker(0.2)"
      radialLabel={e => `${e.label} (${e.text})`}
      radialLabelsSkipAngle={10}
      radialLabelsTextXOffset={6}
      radialLabelsTextColor="#333333"
      radialLabelsLinkOffset={0}
      radialLabelsLinkDiagonalLength={10}
      radialLabelsLinkHorizontalLength={10}
      radialLabelsLinkStrokeWidth={1}
      radialLabelsLinkColor="inherit"
      slicesLabelsSkipAngle={10}
      slicesLabelsTextColor="#333333"
      sliceLabel={e => e.percent}
      animate={false}
      motionStiffness={90}
      motionDamping={15}
      sortByValue={true}
      theme={theme}
      isInteractive={true}
      tooltip={e => <div>{`${e.label}: ${e.text}`}</div>}
    />
  </Div>
);

const Div = styled.div`
  height: ${props => props.height};
  flex: 1;
`;

export default Pie;
