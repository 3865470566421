import React, { Component } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { numberFormat, percentFormat } from '../Utils/Format';

import './index.css';
import logoIcon from '../../svg/logo.svg';

class BillPaymentSyncMIS extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            repay: [],
            compensation: [],
            comment: [],
            data: null,
        };
    }

    componentWillMount() {
        this.loadData();
    }

    loadData = () => {
        (async () => {
            await this.loadRepay();
            await this.loadCompensation();
            await this.loadComment();
            await this.loadBill();
        })();
    };

    loadRepay = async () => {
        const res = await axios.get('/api/v1/pvAccount/repay?month=' + this.props.month);
        if (res.data.status) {
            const { repay } = res.data.result;
            this.setState({ repay });
            //console.log('repay', repay);
        }
    };

    loadCompensation = async () => {
        const res = await axios.get('/api/v1/pvAccount/compensation?month=' + this.props.month);
        if (res.data.status) {
            const { compensation } = res.data.result;
            this.setState({ compensation });
            //console.log('compensation', compensation);
        }
    };

    loadComment = async () => {
        const res = await axios.get('/api/v1/billPayment/comment?month=' + this.props.month);
        if (res.data.status) {
            const { comment } = res.data.result;
            this.setState({ comment });
            //console.log('comment', comment);
        }
    };

    loadBill = async () => {
        const res = await axios.get('/api/v1/billPayment/syncMis?month=' + this.props.month);
        if (res.data.status) {
            const { payment } = res.data.result;
            let lastCompany = '',
                lastBeneficiary = '';
            let company, beneficiary;
            let list = [];
            let commentIndex = 0;
            payment.forEach((elm) => {
                if (lastCompany !== elm.owner) {
                    company = { name: elm.owner, beneficiaryList: [] };
                    list.push(company);
                    lastCompany = elm.owner;
                    lastBeneficiary = '';
                    commentIndex = 0;
                }

                if (lastBeneficiary !== elm.beneficiary) {
                    beneficiary = {
                        name: elm.beneficiary,
                        cashoutAccount: elm.cashoutAccount,
                        //sum: 0,
                        pvList: [],
                        commentList: [],
                    };

                    company.beneficiaryList.push(beneficiary);
                    lastBeneficiary = elm.beneficiary;
                }

                elm.profit =
                    elm.currentProfit + elm.lastAccumulation > 0 ? elm.currentProfit + elm.lastAccumulation : 0;
                //beneficiary.sum += elm.profit;
                beneficiary.pvList.push(elm);

                const compensation = this.state.compensation.filter(
                    (e) => e.pvId === elm.pvId && e.userId === elm.userId
                );
                compensation.forEach((e) => {
                    commentIndex = commentIndex + 1;
                    const row = { name: e.name, profit: e.profit, commentIndex };
                    beneficiary.pvList.push(row);
                    beneficiary.commentList.push(`附註${commentIndex}: ${e.reason}`);
                    //beneficiary.sum += row.profit;
                });

                const repay = this.state.repay.filter((e) => e.pvId === elm.pvId && e.userId === elm.userId);
                repay.forEach((e) => {
                    commentIndex = commentIndex + 1;
                    const row = { name: e.name, profit: e.profit, commentIndex };
                    beneficiary.pvList.push(row);
                    beneficiary.commentList.push(`附註${commentIndex}: ${e.reason}${e.comment ? e.comment : ''}`);
                    //beneficiary.sum += row.profit;
                });

                this.state.comment.forEach((e) => {
                    if (e.pvId === elm.pvId) {
                        commentIndex = commentIndex + 1;
                        beneficiary.commentList.push(`附註${commentIndex}: ${e.content}`);
                    }
                });
            });

            console.log('list', list);

            let list2 = [];
            const ROW_LIMIT = 20;
            let tmpRowCountSum = 0;
            list.forEach((company) => {
                tmpRowCountSum = 0;
                let curCompany = { name: company.name, beneficiaryList: [] };
                const beneficiaryList = company.beneficiaryList;
                beneficiaryList.forEach((beneficiary) => {
                    const pvListCount = beneficiary.pvList.length;
                    if (tmpRowCountSum + pvListCount <= ROW_LIMIT) {
                        //不換頁
                        curCompany.beneficiaryList.push(beneficiary);
                        tmpRowCountSum += pvListCount;
                    } else {
                        //換頁
                        list2.push({ ...curCompany });
                        curCompany.beneficiaryList = [beneficiary];
                        tmpRowCountSum = pvListCount;
                    }
                });
                list2.push({ ...curCompany });
            });

            console.log('list2', list2);

            this.setState({
                data: list2,
                loaded: true,
            });
        } else {
            this.setState({ loaded: true });
        }
    };

    render() {
        const { data } = this.state;
        let content = [];
        if (data) {
            data.forEach((company, i) => {
                content.push(
                    <div className="bill-payment" key={i}>
                        <div className="logo">
                            <img alt="logoIcon" src={logoIcon} />
                        </div>
                        <div className="header">
                            {company.name}_電費單請款明細 ({this.props.month})
            </div>
                        <Content>
                            <div>
                                <table>
                                    <tbody>
                                        <tr>
                                            <th>案場</th>
                                            <th colSpan="2">電費單計費起訖</th>
                                            <th>總收益</th>
                                            <th>佔比</th>
                                            <th>應得收益</th>
                                            <th>上期損益</th>
                                            <th>應請金額</th>
                                            {/*<th>應請小計</th>*/}
                                            <th>受益人名稱</th>
                                            <th>受益人帳戶</th>
                                        </tr>
                                        {company.beneficiaryList.map((beneficiary, j) =>
                                            beneficiary.pvList.map((pv, k) => (
                                                <tr key={j * 10 + k}>
                                                    <td>{pv.name}</td>
                                                    {pv.begin ? (
                                                        <td className="date">{pv.begin}</td>
                                                    ) : (
                                                        <td colSpan={6} className="date">{`參考附註${pv.commentIndex}`}</td>
                                                    )}
                                                    {pv.begin ? <td className="date">{pv.end}</td> : null}
                                                    {pv.begin ? (
                                                        <td className="number">{numberFormat({ value: pv.totalProfit })}</td>
                                                    ) : null}
                                                    {pv.begin ? (
                                                        <td className="number">{percentFormat(parseFloat(pv.percent).toFixed(4))}</td>
                                                    ) : null}
                                                    {pv.begin ? (
                                                        <td className="number">{numberFormat({ value: pv.currentProfit })}</td>
                                                    ) : null}
                                                    {pv.begin ? (
                                                        <td className="number">{numberFormat({ value: pv.lastAccumulation })}</td>
                                                    ) : null}
                                                    <td className="number">{numberFormat({ value: pv.profit })}</td>
                                                    {/*k === 0 ? (
                          <td className="total" rowSpan={beneficiary.pvList.length}>
                            {numberFormat({ value: beneficiary.sum })}
                          </td>
                        ) : null*/}
                                                    {k === 0 ? <td rowSpan={beneficiary.pvList.length}>{beneficiary.name}</td> : null}
                                                    {k === 0 ? (
                                                        <td rowSpan={beneficiary.pvList.length}>{beneficiary.cashoutAccount}</td>
                                                    ) : null}
                                                </tr>
                                            ))
                                        )}
                                    </tbody>
                                </table>
                                <Comment>{`附註: 佔比最多顯示小數點後兩位，僅做為參考用，如要驗算，誤差小於0.005%皆屬正常。`}</Comment>
                                {company.beneficiaryList.map((beneficiary, j) =>
                                    beneficiary.commentList.map((reason, k) => <Comment key={k}>{reason}</Comment>)
                                )}
                            </div>
                            <SignContainer>
                                <Block>
                                    <Sign>(章)</Sign>
                                    <span>
                                        <div>[報表代理人]</div>
                                        <div>索拉能源股份有限公司</div>
                                        <div>日期:</div>
                                    </span>
                                </Block>
                                <Block>
                                    <Sign>(章)</Sign>
                                    <span>
                                        <div>[電廠持有人]</div>
                                        <div>{company.name}</div>
                                        <div>日期:</div>
                                    </span>
                                </Block>
                            </SignContainer>
                        </Content>
                    </div>
                );
            });
        }
        return <div>{content}</div>;
    }
}

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 665px;
`;

const Comment = styled.div`
  margin-top: 5px;
  font-weight: bold;
`;

const SignContainer = styled.div`
  display: flex;
`;

const Block = styled.div`
  display: flex;
  width: 50%;
`;

const Sign = styled.div`
  height: 100px;
  width: 200px;
  margin-right: 15px;
  color: lightgrey;
  border: 1px dashed lightgrey;
`;

export default BillPaymentSyncMIS;
