import React from 'react';
import styled from 'styled-components';
import { ResponsiveBar } from '@nivo/bar';
import { numberFormat } from '../Utils/Format';

const theme = {
  labels: {
    text: {
      fontSize: 14
    }
  },
  legends: {
    text: {
      fontSize: 14
    }
  },
  axis: {
    legend: {
      text: {
        fontSize: 14
      }
    },
    ticks: {
      text: {
        fontSize: 14
      }
    }
  }
};
const Bar = ({ height, keys, data, min, max }) => {
  return (
    <Div height={height}>
      <ResponsiveBar
        data={data}
        keys={keys}
        indexBy="month"
        margin={{
          top: 40,
          right: 250,
          bottom: 75,
          left: 100
        }}
        padding={0.3}
        theme={theme}
        colors="nivo"
        colorBy="id"
        borderColor="inherit:darker(1.6)"
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: '月份',
          legendPosition: 'middle',
          legendOffset: 50
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: '收益(元)',
          legendPosition: 'middle',
          legendOffset: -70,
          format: e => numberFormat({ value: e })
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor="inherit:darker(1.6)"
        labelFormat={e => numberFormat({ value: e })}
        maxValue={max}
        minValue={min}
        animate={false}
        motionStiffness={90}
        motionDamping={15}
        tooltip={e => (
          <div>{`${e.id} ${e.indexValue}: ${numberFormat({ value: e.value, postfix: '元' })}`}</div>
        )}
        legends={[
          {
            dataFrom: 'keys',
            anchor: 'bottom-right',
            direction: 'column',
            justify: false,
            translateX: 120,
            translateY: 0,
            itemsSpacing: 2,
            itemWidth: 100,
            itemHeight: 25,
            itemDirection: 'left-to-right',
            itemOpacity: 0.85,
            symbolSize: 20,
            effects: [
              {
                on: 'hover',
                style: {
                  itemOpacity: 1
                }
              }
            ]
          }
        ]}
      />
    </Div>
  );
};

const Div = styled.div`
  height: ${props => props.height};
  flex: 1;
`;

export default Bar;
