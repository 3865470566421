import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { numberFormat } from '../Utils/Format';

import './index.css';
import logoIcon from '../../svg/logo.svg';

const FixedProfitInvoice = (props) => {
  //義大第2~18年
  const data1 = [
    { repay: 57057, interest: 0 }, //4月
    { repay: 57057, interest: 0 }, //5
    { repay: 0, interest: 57057 }, //6
    { repay: 0, interest: 57057 }, //7
    { repay: 0, interest: 57057 }, //8
    { repay: 0, interest: 57057 }, //9
    { repay: 0, interest: 57057 }, //10
    { repay: 0, interest: 57057 }, //11
    { repay: 0, interest: 57057 }, //12
    { repay: 57057, interest: 0 }, //1
    { repay: 57057, interest: 0 }, //2
    { repay: 57057, interest: 0 }, //3
  ];
  //佳冬第2~18年
  const data2 = [
    { repay: 29420, interest: 0 }, //4
    { repay: 29424, interest: 0 }, //5
    { repay: 0, interest: 29421 }, //6
    { repay: 0, interest: 29421 }, //7
    { repay: 0, interest: 29421 }, //8
    { repay: 0, interest: 29421 }, //9
    { repay: 0, interest: 29421 }, //10
    { repay: 0, interest: 29421 }, //11
    { repay: 0, interest: 29421 }, //12
    { repay: 29420, interest: 0 }, //1
    { repay: 29420, interest: 0 }, //2
    { repay: 29420, interest: 0 }, //3
  ];

  let rows = [];
  let start = moment('2019-04', 'YYYY-MM');
  let t = 0;
  let test = [];
  for (let y = 0; y < 20; y++) {
    for (let m = 0; m < 12; m++) {
      let total = 0;
      let obj1 = {};
      let obj2 = {};
      let month = moment(start).add('months', y * 12 + m);
      if (month.format('YYYY-MM') === '2038-01') {
        obj1.repay = 57057;
        obj1.interest = 0;
        obj2.repay = 29417;
        obj2.interest = 0;
      } else if (month.format('YYYY-MM') >= '2038-02' && month.format('YYYY-MM') <= '2038-05') {
        obj1.repay = 0;
        obj1.interest = 0;
        obj2.repay = 0;
        obj2.interest = 0;
      } else if (month.format('YYYY-MM') >= '2038-06' && month.format('YYYY-MM') <= '2038-12') {
        obj1.repay = 0;
        obj1.interest = 114114;
        obj2.repay = 0;
        obj2.interest = 58842;
      } else if (month.format('YYYY-MM') >= '2039-01') {
        obj1.repay = 0;
        obj1.interest = 0;
        obj2.repay = 0;
        obj2.interest = 0;
      } else if (month.format('YYYY-MM') <= '2020-12') {
        //已產出完畢
        continue;
      } else {
        obj1.repay = data1[m].repay;
        obj1.interest = data1[m].interest;
        obj2.repay = data2[m].repay;
        obj2.interest = data2[m].interest;
      }
      obj1.income = obj1.repay + obj1.interest;
      obj2.income = obj2.repay + obj2.interest;
      total = obj1.income + obj2.income;
      t += total;
      console.log(month.format('YYYY-MM-05'), obj1.repay, obj1.interest, obj2.repay, obj2.interest);
      test.push([month.format('YYYY-MM-05'), obj1.repay, obj1.interest, obj2.repay, obj2.interest]);

      rows.push(
        <div className="fixed-invoice" key={y * 12 + m}>
          <div className="logo">
            <img alt="logoIcon" src={logoIcon} />
          </div>
          <div className="header">{`汗得福克斯教育股份有限公司_電費對帳單 (${month.format('YYYY-MM')})`}</div>
          <Content>
            <div>
              <table>
                <tbody>
                  <tr>
                    <th width="300px">營運公司</th>
                    <th width="300px">案場</th>
                    <th>本期應還本金</th>
                    <th>本期應還利息</th>
                    <th>本期入帳金額</th>
                  </tr>
                  <tr>
                    <td>三電綠能有限公司</td>
                    <td>義大製罐</td>
                    <td className="number">{numberFormat({ value: obj1.repay })}</td>
                    <td className="number">{numberFormat({ value: obj1.interest })}</td>
                    <td className="number">{numberFormat({ value: obj1.income })}</td>
                  </tr>
                  <tr>
                    <td>亮暘綠能服務股份有限公司</td>
                    <td>屏東10M_佳冬鄉塭仔段177地號</td>
                    <td className="number">{numberFormat({ value: obj2.repay })}</td>
                    <td className="number">{numberFormat({ value: obj2.interest })}</td>
                    <td className="number">{numberFormat({ value: obj2.income })}</td>
                  </tr>
                  <tr>
                    <td colSpan="5" className="total">
                      本期應入帳總金額: {numberFormat({ value: total })}
                    </td>
                  </tr>
                </tbody>
              </table>
              <Comment>{`請款說明:`}</Comment>
              <Comment>{`　　1~5月清償本金。`}</Comment>
              <Comment>{`　　6~12月憑發票請款，營運公司須於28號前取得發票。`}</Comment>
            </div>
            <SignContainer>
              <Block>
                <Sign>(章)</Sign>
                <span>
                  <div>[報表代理人]</div>
                  <div>索拉能源股份有限公司</div>
                  <div>日期:</div>
                </span>
              </Block>
              <Block>
                <Sign>(章)</Sign>
                <span>
                  <div>[受益人]</div>
                  <div>汗得福克斯教育股份有限公司</div>
                  <div>日期:</div>
                </span>
              </Block>
            </SignContainer>
          </Content>
        </div>
      );
    }
  }
  console.log(test);
  console.log(t);
  return <div>{rows}</div>;
};

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 665px;
`;

const Comment = styled.div`
  margin-top: 10px;
  font-size: 14px;
`;

const SignContainer = styled.div`
  display: flex;
`;

const Block = styled.div`
  display: flex;
  width: 50%;
`;

const Sign = styled.div`
  height: 125px;
  width: 200px;
  margin-right: 15px;
  color: lightgrey;
  border: 1px dashed lightgrey;
`;

export default FixedProfitInvoice;
