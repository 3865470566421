import React, { Component } from 'react';
import axios from 'axios';
import moment from 'moment';
import styled from 'styled-components';
import { numberFormat, percentFormat } from '../Utils/Format';
import { createYearCostList, getRangeCost, getRangeCostWithEnd, getRangeLoanCostRFD } from '../Utils/Finance';

import './index.css';
import logoIcon from '../../svg/logo.svg';

//依照合約每年約定金額//第三年為最後一年有暫扣款
const contract = {
  亞太國際物流股份有限公司: { interest: 190655, profit: 640281, repay: 0, loanWithHolding: 53404 },
  至怡投資股份有限公司: { interest: 45396, profit: 152446, repay: 0, loanWithHolding: 12720 },
  實豐投資股份有限公司: { interest: 45396, profit: 152446, repay: 0, loanWithHolding: 12720 },
  誼達投資有限公司: { interest: 27236, profit: 91469, repay: 0, loanWithHolding: 7626 },
};

class BillSummaryForOwnerApli extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      data: null,
      beneficiary: null,
      owner: null,
    };
  }

  componentWillMount() {
    (async () => {
      await this.loadData();
    })();
  }

  loadData = async () => {
    const res = await axios.get('/api/v1/billSummaryForOwner/forApli/?month=' + this.props.month);
    if (res.data.status) {
      const { summary, insurance, loan, beneficiary } = res.data.result;
      let lastOwner = '';
      let list = [];
      let company;
      let totalProfit = 0;
      summary.forEach((elm) => {
        if (lastOwner !== elm.owner) {
          //if (lastOwner === '') {
          company = {};
          company.name = elm.owner;
          company.pvList = [];
          list.push(company);
          lastOwner = elm.owner;
        }

        company.pvList.push(elm);
        elm.income = parseInt(elm.income);

        elm.dayCount = moment(elm.end).diff(elm.begin, 'days') + 1;
        elm.rent = Math.round(elm.income * elm.rentRateByIncome);
        const operationList = createYearCostList(
          elm.validityDateBegin,
          20,
          elm.operationRateByCapital * elm.totalCapital
        );
        elm.operate = Math.round(
          getRangeCost(
            operationList,
            elm.begin,
            moment(elm.end)
              .add(1, 'days')
              .format('YYYY-MM-DD')
          )
        );
        elm.service = Math.round((elm.income - elm.meter) * elm.serviceRateByIncome);

        const insuranceList = insurance.filter((i) => i.pvId === elm.pvId);
        elm.insurance = Math.round(
          Math.round(getRangeCostWithEnd(insuranceList, elm.begin, moment(elm.end).add(1, 'days')))
        );

        const loanList = loan.filter((i) => i.pvId === elm.pvId);
        elm.loan = Math.round(getRangeLoanCostRFD(loanList, elm.begin, moment(elm.end).add(1, 'days')));

        elm.profit = elm.income - elm.meter - elm.rent - elm.operate - elm.service - elm.insurance - elm.loan;
        totalProfit += elm.profit;
      });

      company.pvList.push({ name: '合計', profit: totalProfit });

      const totalCapital = beneficiary.reduce((a, b) => a + parseInt(b.amount), 0);
      beneficiary.forEach((elm) => {
        const c = contract[elm.name];
        elm.contract = c;
        elm.percent = parseInt(elm.amount) / totalCapital;
        elm.withhold = -Math.trunc((c.interest + c.profit) * 0.1);
        elm.extraProfit = Math.round((totalProfit * elm.percent - c.interest - c.profit - c.repay) * 0.75);
        elm.pledge = -c.loanWithHolding;
        elm.overpayment = elm.extraProfit + elm.pledge;
        elm.overpaymentWithhold = -Math.trunc(elm.overpayment * 0.1);
        elm.receivable = c.interest + c.repay + elm.withhold + c.profit + elm.extraProfit + elm.pledge + elm.overpaymentWithhold;
      });

      let owner = [];
      owner.push({
        item: '應付金額',
        interest: beneficiary.reduce((a, b) => a + b.contract.interest, 0),
        profit: beneficiary.reduce((a, b) => a + b.contract.profit, 0),
        repay: beneficiary.reduce((a, b) => a + b.contract.repay, 0),
        withhold: beneficiary.reduce((a, b) => a + b.withhold, 0) +
          beneficiary.reduce((a, b) => a + b.overpaymentWithhold, 0),
        extraProfit: beneficiary.reduce((a, b) => a + b.extraProfit, 0),
        pledge: beneficiary.reduce((a, b) => a + b.pledge, 0),
        overpayment: beneficiary.reduce((a, b) => a + b.overpayment, 0),
        total:
          beneficiary.reduce((a, b) => a + b.contract.interest, 0) +
          beneficiary.reduce((a, b) => a + b.contract.profit, 0) +
          beneficiary.reduce((a, b) => a + b.contract.repay, 0) +
          beneficiary.reduce((a, b) => a + b.withhold, 0) +
          beneficiary.reduce((a, b) => a + b.extraProfit, 0) +
          beneficiary.reduce((a, b) => a + b.pledge, 0) +
          beneficiary.reduce((a, b) => a + b.overpaymentWithhold, 0),
      });

      //console.log('list', list);
      this.setState({
        loaded: true,
        data: list,
        beneficiary,
        owner,
      });
    } else {
      this.setState({ loaded: true });
    }
  };

  render() {
    const { data, beneficiary, owner } = this.state;
    let content = [];
    if (data) {
      Object.keys(contract).forEach((invester, k) => {
        data.forEach((company, i) => {
          content.push(
            <div className="bill-summary-for-owner-apli" key={i + k * Object.keys(contract).length}>
              <div className="logo">
                <img alt="logoIcon" src={logoIcon} />
              </div>
              <div className="header">
                {invester}_分潤結算報表 ({this.props.month})
              </div>
              <Content>
                <div>
                  <table>
                    <tbody>
                      <tr>
                        <th>案場</th>
                        <th colSpan="2">電費單計費起訖</th>
                        <th>計費天數</th>
                        <th>電費(未稅)</th>
                        <th>電表租費(未稅)</th>
                        <th>租金(未稅)</th>
                        <th>營運維修費(未稅)</th>
                        <th>索拉服務費(未稅)</th>
                        <th>保險費</th>
                        <th>貸款本息</th>
                        <th>售電利潤(未稅)</th>
                      </tr>
                      {company.pvList.map((pv, j) => (
                        <tr key={j} className={pv.name === '合計' ? 'total' : null}>
                          {pv.name === '合計' ? (
                            <td colSpan="11" className="totalName">
                              {pv.name}
                            </td>
                          ) : (
                            <td>{pv.name}</td>
                          )}
                          {pv.name === '合計' ? null : <td className="date">{pv.begin}</td>}
                          {pv.name === '合計' ? null : <td className="date">{pv.end}</td>}
                          {pv.name === '合計' ? null : (
                            <td className="number">{numberFormat({ value: pv.dayCount })}</td>
                          )}
                          {pv.name === '合計' ? null : (
                            <td className="number">{numberFormat({ value: pv.income })}</td>
                          )}
                          {pv.name === '合計' ? null : (
                            <td className="number">{numberFormat({ value: pv.meter })}</td>
                          )}
                          {pv.name === '合計' ? null : (
                            <td className="number">{numberFormat({ value: pv.rent })}</td>
                          )}
                          {pv.name === '合計' ? null : (
                            <td className="number">{numberFormat({ value: pv.operate })}</td>
                          )}
                          {pv.name === '合計' ? null : (
                            <td className="number">{numberFormat({ value: pv.service })}</td>
                          )}
                          {pv.name === '合計' ? null : (
                            <td className="number">{numberFormat({ value: pv.insurance })}</td>
                          )}
                          {pv.name === '合計' ? null : (
                            <td className="number">{numberFormat({ value: pv.loan })}</td>
                          )}
                          <td className="number">{numberFormat({ value: pv.profit })}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <br />
                  <table>
                    <tbody>
                      <tr>
                        <th colSpan="12">甲方應收</th>
                      </tr>
                      <tr>
                        <th>投資公司</th>
                        <th>出資額</th>
                        <th>佔比</th>
                        <th>應付利息</th>
                        <th>至少利潤</th>
                        <th>應付本金</th>
                        <th>扣繳</th>
                        <th>超額利潤</th>
                        <th>貸款暫扣押金</th>
                        <th>超額給付</th>
                        <th>超額扣繳</th>
                        <th>應收款項</th>
                      </tr>
                      {beneficiary.map((b, j) => (
                        <tr key={j} className={b.name === '合計' ? 'total' : null}>
                          {b.name === '合計' ? <td className="totalName">{b.name}</td> : <td>{b.name}</td>}
                          <td className="number">{numberFormat({ value: b.amount })}</td>
                          <td className="number">{percentFormat(parseFloat(b.percent).toFixed(4))}</td>
                          <td className="number">{numberFormat({ value: b.contract.interest })}</td>
                          <td className="number">{numberFormat({ value: b.contract.profit })}</td>
                          <td className="number">{numberFormat({ value: b.contract.repay })}</td>
                          <td className="number">{numberFormat({ value: b.withhold })}</td>
                          <td className="number">{numberFormat({ value: b.extraProfit })}</td>
                          <td className="number">{numberFormat({ value: -b.contract.loanWithHolding })}</td>
                          <td className="number">{numberFormat({ value: b.overpayment })}</td>
                          <td className="number">{numberFormat({ value: b.overpaymentWithhold })}</td>
                          <td className="number">{numberFormat({ value: b.receivable })}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <br />
                  <table>
                    <tbody>
                      <tr>
                        <th colSpan="7">乙方應付</th>
                      </tr>
                      <tr>
                        <th>項目</th>
                        <th>總應付利息</th>
                        <th>總至少利潤</th>
                        <th>總應還本金</th>
                        <th>總超額給付</th>
                        <th>總扣繳</th>
                        <th>合計</th>
                      </tr>
                      {owner.map((o, j) => (
                        <tr key={j}>
                          <td>{o.item}</td>
                          <td className="number">{numberFormat({ value: o.interest })}</td>
                          <td className="number">{numberFormat({ value: o.profit })}</td>
                          <td className="number">{numberFormat({ value: o.repay })}</td>
                          <td className="number">{numberFormat({ value: o.overpayment })}</td>
                          <td className="number">{numberFormat({ value: o.withhold })}</td>
                          <td className="number">{numberFormat({ value: o.total })}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <br />
                <SignContainer>
                  <Block>
                    <Sign>(章)</Sign>
                    <span>
                      <div>[甲方]</div>
                      <div>{invester}</div>
                      <div>日期:</div>
                    </span>
                  </Block>
                  <Block>
                    <Sign>(章)</Sign>
                    <span>
                      <div>[乙方]</div>
                      <div>{company.name}</div>
                      <div>日期:</div>
                    </span>
                  </Block>
                  <Block>
                    <Sign>(章)</Sign>
                    <span>
                      <div>[報表代理人]</div>
                      <div>微電能源股份有限公司</div>
                      <div>日期:</div>
                    </span>
                  </Block>
                </SignContainer>
              </Content>
            </div>
          );
        });
      });
    }
    return <div>{content}</div>;
  }
}

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 665px;
`;

const SignContainer = styled.div`
  display: flex;
`;

const Block = styled.div`
  display: flex;
  width: 50%;
`;

const Sign = styled.div`
  height: 200px;
  width: 160px;
  margin-right: 15px;
  color: lightgrey;
  border: 1px dashed lightgrey;
`;

export default BillSummaryForOwnerApli;
