import React, { Component } from 'react';
import axios from 'axios';
import moment from 'moment';
import styled from 'styled-components';
import TestNivoPie from './TestNivoPie';
import TestNivoBar from './TestNivoBar';
import TestTable1 from './TestTable1';
import TestTable2 from './TestTable2';
import { numberFormat, percentFormat } from '../Utils/Format';

const preparePieData = (data) => {
  const total1 = data.reduce((t, x) => t + x.value, 0);

  const newData = data.map((x) => {
    x.percent = ((x.value * 100) / total1).toFixed(0) + '%';
    x.text = numberFormat({ value: Math.round(x.value), postfix: '元' });
    return x;
  });

  return newData;
};

class InvestmentReport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      totalInvestment: 0,
      userName: undefined,
      assets: [],
      totalProfit: 0,
      totalUnrealizedProfit: 0,
      profitList: [],
      profitListByMonth: [],
      profitKeys: [],
      profitMin: undefined,
      profitMax: undefined,
      detailList: {},
      totalProfitLastYear: 0,
    };
  }

  componentDidMount() {
    if (this.props.userId) {
      this.loadUser();
      this.loadAssets();
    }
  }

  loadUser = () => {
    axios
      .get('/api/v1/investment/user', {
        params: {
          userId: this.props.userId,
        },
      })
      .then((res) => {
        if (res.data.status === true && res.data.result.user.length > 0) {
          this.setState({ userName: res.data.result.user[0].name });
        } else {
          // TODO: handle error_code, msg
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  loadAssets = () => {
    axios
      .get('/api/v1/investment/assets', {
        params: {
          userId: this.props.userId,
        },
      })
      .then((res) => {
        if (res.data.status === true) {
          const totalInvestment = res.data.result.assets.reduce(
            (a, v) => a + parseFloat(v.amount, 10) * 10,
            0
          );
          let detailList = {};
          let assets = res.data.result.assets.map((v) => {
            let powerDay = 0,
              powerDayThisYear = 0;
            const validityDateBegin = moment(v.validityDateBegin, 'YYYY-MM-DD');
            if (validityDateBegin.isValid()) {
              powerDay = moment().diff(v.validityDateBegin, 'days');

              const yearBegin = moment().startOf('years');
              if (validityDateBegin.isBefore(yearBegin)) {
                powerDayThisYear = moment().diff(yearBegin, 'days');
              } else {
                powerDayThisYear = powerDay;
              }
            }
            detailList[v.pvId] = {
              name: v.name,
              status: v.status,
              validityDateBegin: v.validityDateBegin,
              powerDayThisYear: powerDayThisYear,
              powerDay: powerDay,
              achievementRate: 0,
              investment: parseFloat(v.amount, 10) * 10,
              profit: 0,
              unrealizedProfit: 0,
            };
            return {
              id: v.pvId,
              label: v.name,
              value: parseFloat(v.amount, 10) * 10,
            };
          });

          assets = preparePieData(assets);
          this.setState({
            totalInvestment: totalInvestment,
            assets,
            detailList,
          });

          this.loadAchievementRate();
          this.loadProfit();
          this.loadProfitByMonth();
        } else {
          // TODO: handle error_code, msg
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  loadAchievementRate = () => {
    axios
      .get('/api/v1/investment/achievementRate')
      .then((res) => {
        if (res.data.status === true) {
          let { detailList } = this.state;
          res.data.result.data.forEach((v) => {
            if (detailList[v.id]) {
              detailList[v.id].achievementRate = v.achievementRate;
            }
          });
          this.setState({ detailList });
        } else {
          // TODO: handle error_code, msg
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  loadProfit = () => {
    axios
      .get('/api/v1/investment/profit', {
        params: {
          userId: this.props.userId,
        },
      })
      .then((res) => {
        if (res.data.status === true) {
          let { detailList } = this.state;
          const totalProfit = res.data.result.profit.reduce((a, v) => a + parseFloat(v.value, 10), 0);
          let profitList = res.data.result.profit.map((v) => {
            detailList[v.pvId].profit = parseFloat(v.value, 10);
            return {
              id: v.pvId,
              label: v.name,
              value: parseFloat(v.value, 10),
            };
          });

          //console.log(profitList);

          this.setState({ totalProfit: totalProfit, detailList });

          this.loadUnrealizedProfit(profitList);
        } else {
          // TODO: handle error_code, msg
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  loadProfitByMonth = () => {
    //TODO: load Simulate
    axios
      .get('/api/v1/investment/profitByMonth', {
        params: {
          userId: this.props.userId,
          monthBegin: moment().subtract(12, 'month').format('YYYY-MM'),
          monthEnd: moment().format('YYYY-MM'),
        },
      })
      .then((res) => {
        if (res.data.status === true) {
          const totalProfitLastYear = res.data.result.profit.reduce(
            (t, x) => t + parseFloat(x.totalProfit),
            0
          );
          this.setState({ totalProfitLastYear });
          //按照月份進行分類
          let profitDictByMonth = {};
          let keys = {};
          res.data.result.profit.forEach((v) => {
            keys[v.name] = true;
            if (profitDictByMonth[v.month] === undefined) {
              profitDictByMonth[v.month] = { month: v.month, max: 0, min: 0 };
            }
            const value = parseInt(v.totalProfit, 10);
            profitDictByMonth[v.month][v.name] = value;
            if (value > 0) {
              profitDictByMonth[v.month].max += value;
            } else if (value < 0) {
              profitDictByMonth[v.month].min += value;
            }
          });
          //console.log('profitDictByMonth', profitDictByMonth);

          //轉成List
          let profitListByMonth = [];
          //let minValue = 0;
          //let maxValue = 0;
          Object.keys(profitDictByMonth).forEach(function (key) {
            const monthObj = profitDictByMonth[key];
            profitListByMonth.push(monthObj);
            //minValue = Math.min(minValue, monthObj.min);
            //maxValue = Math.max(maxValue, monthObj.max);
          });
          console.log('profitListByMonth', profitListByMonth);

          const profitKeys = Object.keys(keys);

          //this.setState({ profitListByMonth, profitKeys, profitMin: minValue, profitMax: maxValue });

          this.loadUnrealizedProfitByMonth(profitDictByMonth, profitKeys);
        } else {
          // TODO: handle error_code, msg
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  loadUnrealizedProfit = (profitList) => {
    axios
      .get('/api/v1/investment/unrealizedProfit', {
        params: {
          userId: this.props.userId,
        },
      })
      .then((res) => {
        if (res.data.status === true) {
          let { detailList } = this.state;
          const totalUnrealizedProfit = res.data.result.unrealizedProfit.reduce(
            (a, v) => a + parseFloat(v.value, 10),
            0
          );
          profitList.push({
            id: '未分配收益',
            label: '未分配收益',
            value: Math.round(totalUnrealizedProfit),
          });

          res.data.result.unrealizedProfit.forEach((v) => {
            detailList[v.pvId].unrealizedProfit = parseFloat(v.value, 10);
          });

          profitList = preparePieData(profitList);

          this.setState({ totalUnrealizedProfit, detailList, profitList });
        } else {
          // TODO: handle error_code, msg
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  loadUnrealizedProfitByMonth = (profitDictByMonth, profitKeys) => {
    //TODO: load Simulate
    axios
      .get('/api/v1/investment/unrealizedProfitByMonth', {
        params: {
          userId: this.props.userId,
          monthBegin: moment().subtract(12, 'month').format('YYYY-MM'),
          monthEnd: moment().format('YYYY-MM'),
        },
      })
      .then((res) => {
        if (res.data.status === true) {
          if (res.data.result.unrealizedProfit.length > 0) {
            profitKeys.push('未分配收益');
          }
          res.data.result.unrealizedProfit.forEach((v) => {
            if (profitDictByMonth[v.month] === undefined) {
              profitDictByMonth[v.month] = { month: v.month, max: 0, min: 0 };
            }
            const value = parseInt(v.totalProfit, 10);
            profitDictByMonth[v.month]['未分配收益'] = value;
            if (value > 0) {
              profitDictByMonth[v.month].max += value;
            } else if (value < 0) {
              profitDictByMonth[v.month].min += value;
            }
          });
          //console.log('profitDictByMonth', profitDictByMonth);

          //轉成List
          let profitListByMonth = [];
          let minValue = 0;
          let maxValue = 0;
          Object.keys(profitDictByMonth).forEach(function (key) {
            const monthObj = profitDictByMonth[key];
            profitListByMonth.push(monthObj);
            minValue = Math.min(minValue, monthObj.min);
            maxValue = Math.max(maxValue, monthObj.max);
          });
          //console.log('profitListByMonth', profitListByMonth);
          profitListByMonth.sort((a, b) => (a.month > b.month ? 1 : -1));
          this.setState({
            profitListByMonth,
            profitKeys,
            profitMin: minValue,
            profitMax: maxValue,
          });
        } else {
          // TODO: handle error_code, msg
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  render() {
    return (
      <Main>
        <Head>
          SOLA Bloc 投資月報
          <Company>{this.state.userName}</Company>
          <Date>{moment().format('YYYY-MM-DD')}</Date>
        </Head>
        <Div>
          <Block>
            <Title>投資組合</Title>
            <Hr />
            <Total>
              <div>
                {`總投資金額: ${numberFormat({
                  value: this.state.totalInvestment,
                  postfix: '元',
                })}`}
              </div>
              <div>
                {`報酬率
                [已分配: ${percentFormat((this.state.totalProfit / this.state.totalInvestment).toFixed(4))}]
                [未分配: ${percentFormat(
                  (this.state.totalUnrealizedProfit / this.state.totalInvestment).toFixed(4)
                )}]
                [合計: ${percentFormat(
                  (
                    (this.state.totalProfit + this.state.totalUnrealizedProfit) /
                    this.state.totalInvestment
                  ).toFixed(4)
                )}] `}
              </div>
            </Total>
            <TestNivoPie height="360px" data={this.state.assets} />
          </Block>
          <ColumnGap />
          <Block>
            <Title>收益分布</Title>
            <Hr />
            <Total>
              {`收益
              [已分配: ${numberFormat({
                value: Math.round(this.state.totalProfit),
                postfix: '元',
              })}]
              [未分配:${numberFormat({
                value: Math.round(this.state.totalUnrealizedProfit),
                postfix: '元',
              })}]
              [合計: ${numberFormat({
                value: Math.round(this.state.totalProfit + this.state.totalUnrealizedProfit),
                postfix: '元',
              })}] `}
            </Total>
            <TestNivoPie height="360px" data={this.state.profitList} />
          </Block>
        </Div>
        <Div>
          <Block>
            <Title>近一年逐月收益分布</Title>
            <Hr />
            <Total>
              {`近一年報酬率
              [已分配: ${percentFormat(
                (this.state.totalProfitLastYear / this.state.totalInvestment).toFixed(4)
              )}]
              [未分配: ${percentFormat(
                (this.state.totalUnrealizedProfit / this.state.totalInvestment).toFixed(4)
              )}]
              [合計: ${percentFormat(
                (
                  (this.state.totalProfitLastYear + this.state.totalUnrealizedProfit) /
                  this.state.totalInvestment
                ).toFixed(4)
              )}] `}
            </Total>
            <TestNivoBar
              height="500px"
              keys={this.state.profitKeys}
              data={this.state.profitListByMonth}
              min={this.state.profitMin}
              max={this.state.profitMax}
            />
          </Block>
        </Div>
        <Div>
          <Block>
            <Title>投資明細</Title>
            <Hr />
            <TestTable2 data={this.state.detailList} />
          </Block>
        </Div>
        <Div>
          <Block>
            <Title>發電狀況</Title>
            <Hr />
            <TestTable1 data={this.state.detailList} />
          </Block>
        </Div>

        {/*}
        <Div>
          <Block>
            <Title>電廠進度</Title>
            <Hr />
            <Desc>
            </Desc>
          </Block>
          <ColumnGap />
          <Block>
            <Title>SOLA 資訊</Title>
            <Hr />
            <Desc></Desc>
          </Block>
        </Div>
        */}
        <Div>
          <Block>
            <Title />
            <Hr />
            <Desc style={{ color: 'red', fontSize: '14px' }}>
              *本公司所提供之資訊，僅供接收人之參考用途。本公司當盡力提供正確之資訊，所載資料均來自或本諸我們相信可靠之來源，但對其完整性、即時性和正確性不做任何擔保，，且不承擔進行修改任何過去資訊之責任。本公司所提供之資訊，並非投資意見，亦不構成任何之要約、要約引誘或建議，且未考慮接收人個人需要、投資目標及特定財政狀況。任何人因信賴此等資料而做出或改變投資決策，須自行承擔結果。本文件所載資料或任何部份均不可以進行抄錄、翻印或另作派發。
            </Desc>
          </Block>
        </Div>
      </Main>
    );
  }
}

const Main = styled.div`
  margin: 0 auto;
  max-width: 1600px;
  overflow: auto;
`;

const Head = styled.div`
  font-size: 42px;
  font-weight: bold;
  text-align: center;
  color: #f7b100;
`;

const Company = styled.div`
  font-size: 20px;
  color: black;
  margin-top: 20px;
`;

const Date = styled.div`
  font-size: 20px;
  color: black;
`;

const Div = styled.div`
  height: ${(props) => props.height};
  display: flex;
  margin: 30px;
`;

const Block = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const ColumnGap = styled.div`
  width: 50px;
`;

const Title = styled.div`
  font-size: 25px;
  font-weight: bold;
`;

const Hr = styled.hr`
  height: 1px;
  margin: 0px;
  border: none;
  border-top: 1px solid #f7b100;
`;

const Total = styled.div`
  margin: 10px 0;
  font-size: 22px;
  text-align: center;
`;

const Desc = styled.div`
  margin: 10px 0 10px 30px;
  font-size: 22px;
`;

export default InvestmentReport;
