import React, { Component } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { numberFormat } from '../Utils/Format';

import './index.css';
import logoIcon from '../../svg/logo.svg';

class BillSummaryForOwnerSyncMIS extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            comment: [],
            billFix: [],
            data: null,
        };
    }

    componentWillMount() {
        (async () => {
            await this.loadComment();
            await this.loadBillFix();
            await this.loadData();
        })();
    }

    loadComment = async () => {
        const res = await axios.get('/api/v1/billSummaryForOwner/comment?month=' + this.props.month);
        if (res.data.status) {
            const { comment } = res.data.result;
            this.setState({ comment });
        }
    };

    loadBillFix = async () => {
        const res = await axios.get('/api/v1/billSummaryForOwner/billFix?month=' + this.props.month);
        if (res.data.status) {
            const { billFix } = res.data.result;
            this.setState({ billFix });
            console.log('billFix', billFix);
        }
    };

    loadData = async () => {
        const { comment, billFix } = this.state;

        const res = await axios.get('/api/v1/billSummaryForOwner/syncMis?month=' + this.props.month);
        if (res.data.status) {
            const { summary } = res.data.result;
            let lastOwner = '';
            let list = [];
            let company;
            summary.forEach((elm) => {
                if (lastOwner !== elm.owner) {
                    //if (lastOwner === '') {
                    company = {};
                    company.name = elm.owner;
                    company.pvList = [];
                    company.commentList = [];
                    list.push(company);
                    lastOwner = elm.owner;
                }

                company.pvList.push(elm);
                for (let i = 0; i < comment.length; i++) {
                    if (comment[i].pvId === elm.pvId) {
                        company.commentList.push(comment[i].content);
                    }
                }

                for (let i = 0; i < billFix.length; i++) {
                    if (billFix[i].pvId === elm.pvId) {
                        billFix[i].name = elm.name;
                        billFix[i].commentIndex = company.commentList.length;
                        company.pvList.push(billFix[i]);
                        company.commentList.push(billFix[i].comment);
                    }
                }
            });

            list.forEach((company) => {
                const total = company.pvList.reduce(
                    (acc, pv) => {
                        //因應電費單修正
                        if (pv.begin === undefined) {
                            acc.income += pv.income ? parseInt(pv.income) : 0;
                            acc.meter += pv.meter ? parseInt(pv.meter) : 0;
                            acc.profit += pv.profit ? parseInt(pv.profit) : 0;
                            acc.rent += pv.rent ? parseInt(pv.rent) : 0;
                            acc.operate += pv.operate ? parseInt(pv.operate) : 0;
                            acc.service += pv.service ? parseInt(pv.service) : 0;
                            acc.insurance += pv.insurance ? parseInt(pv.insurance) : 0;
                            acc.loan += pv.loan ? parseInt(pv.loan) : 0;
                        } else {
                            acc.income += parseInt(pv.income);
                            acc.meter += parseInt(pv.meter);
                            acc.profit += parseInt(pv.profit);
                            acc.rent += parseInt(pv.rent);
                            acc.operate += parseInt(pv.operate);
                            acc.service += parseInt(pv.service);
                            acc.insurance += parseInt(pv.insurance);
                            acc.loan += parseInt(pv.loan);
                        }
                        return acc;
                    },
                    {
                        name: '小計',
                        income: 0,
                        meter: 0,
                        profit: 0,
                        rent: 0,
                        operate: 0,
                        service: 0,
                        insurance: 0,
                        loan: 0,
                    }
                );
                company.pvList.push(total);
            });

            console.log('list', list);

            let list2 = [];
            const ROW_LIMIT = 20;
            list.forEach((company) => {
                let curPage = -1;
                let curCompany;
                company.pvList.forEach((pv, i) => {
                    const page = Math.floor(i / ROW_LIMIT);
                    if (curPage !== page) {
                        curPage = page;
                        curCompany = {};
                        curCompany.name = company.name;
                        curCompany.pvList = [];
                        curCompany.commentList = company.commentList;
                        list2.push(curCompany);
                    }
                    curCompany.pvList.push(pv);
                });
            });

            console.log('list2', list2);

            this.setState({
                data: list2,
                loaded: true,
            });
        } else {
            this.setState({ loaded: true });
        }
    };

    render() {
        const { data } = this.state;
        let content = [];
        if (data) {
            data.forEach((company, i) => {
                content.push(
                    <div className="bill-summary-for-owner" key={i}>
                        <div className="logo">
                            <img alt="logoIcon" src={logoIcon} />
                        </div>
                        <div className="header">
                            {company.name}_電費單月結報表 ({this.props.month})
            </div>
                        <Content>
                            <div>
                                <table>
                                    <tbody>
                                        <tr>
                                            <th>案場</th>
                                            <th colSpan="2">電費單計費起訖</th>
                                            <th>總電費(含稅)</th>
                                            <th>電表租費</th>
                                            <th>租金</th>
                                            <th>保險費</th>
                                            <th>貸款本息</th>
                                            <th>營運管理費</th>
                                            <th>索拉服務費</th>
                                            <th>受益權分潤</th>
                                        </tr>
                                        {company.pvList.map((pv, j) => (
                                            <tr key={j} className={pv.name === '小計' ? 'total' : null}>
                                                {pv.name === '小計' ? (
                                                    <td colSpan="3" className="totalName">
                                                        {pv.name}
                                                    </td>
                                                ) : (
                                                    <td>{pv.name}</td>
                                                )}
                                                {pv.name === '小計' ? null : (pv.begin === undefined ? <td className="date" colSpan="2">{`附註${pv.commentIndex + 1}`}</td> : <td className="date">{pv.begin}</td>)}
                                                {pv.name === '小計' || pv.begin === undefined ? null : <td className="date">{pv.end}</td>}
                                                <td className="number">{numberFormat({ value: pv.income })}</td>
                                                <td className="number">{numberFormat({ value: pv.meter })}</td>
                                                <td className="number">{numberFormat({ value: pv.rent })}</td>
                                                <td className="number">{numberFormat({ value: pv.insurance })}</td>
                                                <td className="number">{numberFormat({ value: pv.loan })}</td>
                                                <td className="number">{numberFormat({ value: pv.operate })}</td>
                                                <td className="number">{numberFormat({ value: pv.service })}</td>
                                                <td className="number">{numberFormat({ value: pv.profit })}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                {company.commentList.map((content, j) => (
                                    <Comment key={j}>{`附註${j + 1}: ${content}`}</Comment>
                                ))}
                            </div>
                            <SignContainer>
                                <Block>
                                    <Sign>(章)</Sign>
                                    <span>
                                        <div>[報表代理人]</div>
                                        <div>索拉能源股份有限公司</div>
                                        <div>日期:</div>
                                    </span>
                                </Block>
                                <Block>
                                    <Sign>(章)</Sign>
                                    <span>
                                        <div>[電廠持有人]</div>
                                        <div>{company.name}</div>
                                        <div>日期:</div>
                                    </span>
                                </Block>
                            </SignContainer>
                        </Content>
                    </div>
                );
            });
        }
        return <div>{content}</div>;
    }
}

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 665px;
`;

const Comment = styled.div`
  margin-top: 5px;
  font-weight: bold;
`;

const SignContainer = styled.div`
  display: flex;
`;

const Block = styled.div`
  display: flex;
  width: 50%;
`;

const Sign = styled.div`
  height: 125px;
  width: 200px;
  margin-right: 15px;
  color: lightgrey;
  border: 1px dashed lightgrey;
`;

export default BillSummaryForOwnerSyncMIS;
