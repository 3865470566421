import React, { Component } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { numberFormat, percentFormat } from '../Utils/Format';

import './index.css';
import logoIcon from '../../svg/logo.svg';

const getSpvCostTotal = (companyId) => {
  switch (companyId) {
    case 1:
    case 13:
      return 100000;
    case 15:
      return 50000;
    default:
      return undefined;
  }
};

class SpvDividend extends Component {
  constructor(props) {
    super(props);

    this.state = {
      spvCostTotal: getSpvCostTotal(parseInt(this.props.companyId)),
      shareholderInvestment: null,
      data: null,
    };
  }

  componentWillMount() {
    this.loadData();
  }

  loadData = () => {
    (async () => {
      await this.loadShareholderInvestment();
      await this.loadSpvDividend();
    })();
  };

  loadShareholderInvestment = async () => {
    const res = await axios.get(
      '/api/v1/spvDividend/shareholderInvestment?companyId=' + this.props.companyId
    );
    if (res.data.status) {
      const { shareholderInvestment } = res.data.result;
      this.setState({ shareholderInvestment });
      console.log('shareholderInvestment', shareholderInvestment);
    }
  };

  //特別股分類(之後應該補進DB)
  getSpecialSharesName = (investId) => {
    switch (investId) {
      //索拉艾倫
      case 276:
      case 277:
      case 278:
      case 279:
        return 'A.索拉艾倫.甲種特別股';
      case 264:
      case 265:
        return 'B.索拉艾倫.乙種特別股';
      case 266:
      case 267:
        return 'C.索拉艾倫.丙種特別股';
      case 268:
      case 269:
      case 270:
        return 'D.索拉艾倫.丁種特別股';
      case 271:
      case 272:
      case 273:
      case 274:
        return 'E.索拉艾倫.戊種特別股';
      case 275:
        return 'F.索拉艾倫.已種特別股';
      //索拉陽光
      case 253:
        return 'G.索拉艾倫.庚種特別股';
      case 254:
      case 258:
        return 'H.索拉艾倫.辛種特別股';
      case 255:
      case 256:
        return 'I.索拉艾倫.壬種特別股';
      case 257:
        return 'J.索拉艾倫.癸種特別股';
      //索拉清華
      case 259:
      case 260:
      case 261:
      case 262:
      case 263:
        return 'A.索拉清華.甲種特別股';
      default:
        return 'A.SPV.普通股';
    }
  };

  loadSpvDividend = async () => {
    const res = await axios.get(`/api/v1/spvDividend?companyId=` + this.props.companyId);
    if (res.data.status) {
      let { spvDividend } = res.data.result;
      console.log('spvDividend', spvDividend);

      let totalProfit = 0;
      //統計可分潤金額
      for (let i = 0; i < spvDividend.length; i++) {
        let pv = spvDividend[i];
        pv.userProfitOriginal = parseInt(pv.userProfit);
        console.log(pv.pvId, pv.userProfit, parseInt(pv.userProfit) + parseInt(pv.compensation));

        pv.userProfit = parseInt(pv.userProfit);
        //加上保發補償
        pv.userProfit += parseInt(pv.compensation);
        totalProfit += pv.userProfit;
      }
      console.log('totalProfit', totalProfit);

      //分攤固定成本, 計算應分潤金額
      let spvCostTotalResult = 0;
      let finalProfitTotal = 0;
      let specialShares = {};
      let dividendByShareholder = {};
      for (let i = 0; i < spvDividend.length; i++) {
        let pv = spvDividend[i];
        pv.spvCost = Math.ceil((this.state.spvCostTotal / totalProfit) * pv.userProfit);
        spvCostTotalResult += pv.spvCost;
        pv.userProfit -= pv.spvCost;
        finalProfitTotal += pv.userProfit;
        console.log(pv.pvId, pv.spvCost, pv.userProfit);

        //計算每個股東所投資的案場應分潤金額
        for (let j = 0; j < this.state.shareholderInvestment.length; j++) {
          const shareholder = this.state.shareholderInvestment[j];
          if (shareholder.pvId !== pv.pvId) {
            continue;
          }

          const percent = shareholder.invest / pv.capital;
          const profit = Math.floor(percent * pv.userProfit);
          shareholder.profitObj = { pv, percent, profit };

          const ssname = this.getSpecialSharesName(shareholder.investmentId);
          if (specialShares[ssname] === undefined) {
            specialShares[ssname] = { totalDividend: 0, dividendList: [] };
          }
          specialShares[ssname].totalDividend += profit;
          specialShares[ssname].dividendList.push(shareholder);

          if (dividendByShareholder[ssname] === undefined) {
            dividendByShareholder[ssname] = { totalDividend: 0, shareholder: {} };
          }

          if (dividendByShareholder[ssname].shareholder[shareholder.name] === undefined) {
            dividendByShareholder[ssname].shareholder[shareholder.name] = {
              invest: 0,
              profit: 0,
              dividend: 0,
              info: [],
            };
          }
          dividendByShareholder[ssname].shareholder[shareholder.name].invest += shareholder.invest;
          dividendByShareholder[ssname].shareholder[shareholder.name].profit += profit;
          dividendByShareholder[ssname].shareholder[shareholder.name].info.push(shareholder);
        }
      }
      console.log('spvCostTotalResult', spvCostTotalResult);
      console.log('finalProfitTotal', finalProfitTotal);
      console.log(specialShares);
      const dividendByShareholderList = Object.keys(dividendByShareholder);
      let toExcel = [];
      for (let i = 0; i < dividendByShareholderList.length; i++) {
        const ssname = dividendByShareholderList[i];
        const shareholderList = Object.keys(dividendByShareholder[ssname].shareholder);
        let dividendTotal = 0;
        for (let j = 0; j < shareholderList.length; j++) {
          const name = shareholderList[j];
          const dividend = Math.round(dividendByShareholder[ssname].shareholder[name].profit / 10) * 10;
          dividendByShareholder[ssname].shareholder[name].dividend = dividend;
          dividendTotal += dividend;
          console.log(
            '*',
            ssname,
            ',',
            dividendByShareholder[ssname].shareholder[name].info[0].userId,
            ',',
            name,
            ',',
            dividend,
            ',',
            dividendByShareholder[ssname].shareholder[name].info[0].bankName,
            ',',
            dividendByShareholder[ssname].shareholder[name].info[0].bankAccount
          );
          toExcel.push([
            ssname,
            dividendByShareholder[ssname].shareholder[name].info[0].userId,
            name,
            dividend,
            dividendByShareholder[ssname].shareholder[name].info[0].bankName,
            dividendByShareholder[ssname].shareholder[name].info[0].bankAccount,
          ]);
        }
        dividendByShareholder[ssname].dividendTotal = dividendTotal;
      }
      console.log(dividendByShareholder);
      console.log('toExcel:');
      console.log(JSON.stringify(toExcel));
      console.log(
        'total',
        toExcel.reduce((a, b) => a + b[3], 0)
      );

      this.setState({ data: dividendByShareholder });
    }
  };

  createShareholderReport = (shareholder) => {
    let pvList = [];
    let spvList = [];
    let dividendList = [];

    for (let i = 0; i < shareholder.info.length; i++) {
      const investment = shareholder.info[i];
      const { pv } = investment.profitObj;
      pvList.push(
        <tr key={i}>
          <td>{pv.name}</td>
          <td className="number">{numberFormat({ value: pv.ownedCapital })}</td>
          <td className="number">{numberFormat({ value: pv.income })}</td>
          <td className="number">{numberFormat({ value: pv.meterCost })}</td>
          <td className="number">{numberFormat({ value: pv.rentCost })}</td>
          <td className="number">{numberFormat({ value: pv.operateCost })}</td>
          <td className="number">{numberFormat({ value: pv.serviceCost })}</td>
          <td className="number">{numberFormat({ value: pv.insuranceCost })}</td>
          <td className="number">{numberFormat({ value: pv.loanCost })}</td>
          <td className="number">{numberFormat({ value: pv.profit })}</td>
          <td className="number">{numberFormat({ value: pv.userCompensation })}</td>
        </tr>
      );
      spvList.push(
        <tr key={i}>
          <td>{pv.name}</td>
          <td className="number">{numberFormat({ value: pv.capital })}</td>
          <td className="number">{percentFormat(parseFloat(pv.percentage).toFixed(4))}</td>
          <td className="date">{pv.rangeBegin}</td>
          <td className="date">{pv.rangeEnd}</td>
          <td className="number">{numberFormat({ value: pv.userProfitOriginal })}</td>
          <td className="number">{numberFormat({ value: pv.userCompensation })}</td>
          <td className="number">
            {numberFormat({ value: pv.userProfitOriginal + parseInt(pv.userCompensation) })}
          </td>
          <td className="number">{numberFormat({ value: pv.spvCost })}</td>
          <td className="number">{numberFormat({ value: pv.userProfit })}</td>
        </tr>
      );

      if (i === 0) {
        dividendList.push(
          <tr key={i}>
            <td>{pv.name}</td>
            <td className="number">{numberFormat({ value: investment.invest })}</td>
            <td className="number">{percentFormat(parseFloat(investment.profitObj.percent).toFixed(4))}</td>
            <td className="number">{numberFormat({ value: investment.profitObj.profit })}</td>
            <td className="number" rowSpan={shareholder.info.length}>
              {numberFormat({ value: shareholder.dividend / 10 })}
            </td>
            <td className="number" rowSpan={shareholder.info.length}>
              {numberFormat({ value: shareholder.dividend })}
            </td>
            <td className="number" rowSpan={shareholder.info.length}>
              {percentFormat(parseFloat(shareholder.profit / shareholder.invest).toFixed(4))}
            </td>
          </tr>
        );
      } else {
        dividendList.push(
          <tr key={i}>
            <td>{pv.name}</td>
            <td className="number">{numberFormat({ value: investment.invest })}</td>
            <td className="number">{percentFormat(parseFloat(investment.profitObj.percent).toFixed(4))}</td>
            <td className="number">{numberFormat({ value: investment.profitObj.profit })}</td>
          </tr>
        );
      }
    }

    return (
      <div>
        <Title>A. 公司帳務</Title>
        <table>
          <tbody>
            <tr>
              <th>案場名稱</th>
              <th>投入資金</th>
              <th>持有比例</th>
              <th>電費起始日</th>
              <th>電費結束日</th>
              <th>電費收益</th>
              <th>保發補償</th>
              <th>入帳金額</th>
              <th>成本分攤</th>
              <th>應分金額</th>
            </tr>
            {spvList}
          </tbody>
        </table>
        <br />
        <Title>B. 股東帳務</Title>
        <table style={{ width: '65%' }}>
          <tbody>
            <tr>
              <th>案場名稱</th>
              <th>投入金額</th>
              <th>應得比例</th>
              <th>應得分潤</th>
              <th>買回股數</th>
              <th>買回金額</th>
              <th>收益率</th>
            </tr>
            {dividendList}
          </tbody>
        </table>
        <br />
        <Comment>{`附註1: 比例最多顯示小數點後兩位，僅做為參考用，如要驗算，誤差小於0.005%皆屬正常。`}</Comment>
        <Comment>{`附註2: 本次分潤方式，以特別股買回方式進行。`}</Comment>
      </div>
    );
  };

  //股東分潤報表
  render2 = () => {
    const { data } = this.state;

    if (data) {
      return (
        <div>
          {Object.keys(data)
            .sort()
            .map((ssname, i) =>
              Object.keys(data[ssname].shareholder)
                .sort()
                .map((name, j) => (
                  <div className="spv-dividend" key={i * 10 + j}>
                    <div className="logo">
                      <img alt="logoIcon" src={logoIcon} />
                    </div>
                    <div className="header">{`${ssname.split('.')[1]} 110上半年度 分潤報表`}</div>
                    <div className="header">{`${ssname.split('.')[2]} ${name}`}</div>
                    <Content>
                      <div>
                        <div>{this.createShareholderReport(data[ssname].shareholder[name])}</div>
                      </div>
                    </Content>
                  </div>
                ))
            )}
        </div>
      );
    } else {
      return null;
    }
  };

  //特別股買回匯款報表
  render1 = () => {
    const { data } = this.state;

    if (data) {
      return (
        <div className="spv-dividend">
          {Object.keys(data)
            .sort()
            .map((ssname, i) => (
              <div key={i}>
                <table>
                  <tbody>
                    <tr>
                      <th>名稱</th>
                      <th>買回股數</th>
                      <th>買回金額</th>
                      <th colSpan={2}>帳戶</th>
                    </tr>
                    <tr>
                      <th>{`${ssname.split('.')[1]}${ssname.split('.')[2]}`}</th>
                      <td className="number">
                        {numberFormat({
                          value: data[ssname].dividendTotal / 10,
                          postfix: '股',
                        })}
                      </td>
                      <td className="number">
                        {numberFormat({
                          value: data[ssname].dividendTotal,
                          postfix: '元',
                        })}
                      </td>
                      <td></td>
                    </tr>
                    {Object.keys(data[ssname].shareholder)
                      .sort()
                      .map((name, j) => (
                        <tr key={j}>
                          <td className="info">{name}</td>
                          <td className="number">
                            {numberFormat({
                              value: data[ssname].shareholder[name].dividend / 10,
                              postfix: '股',
                            })}
                          </td>
                          <td className="number">
                            {numberFormat({
                              value: data[ssname].shareholder[name].dividend,
                              postfix: '元',
                            })}
                          </td>
                          <td>
                            {`${data[ssname].shareholder[name].info[0].bankName} ${data[ssname].shareholder[name].info[0].bankAccount}`}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                <br />
              </div>
            ))}
        </div>
      );
    } else {
      return null;
    }
  };

  render() {
    return this.render2();
  }
}

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Title = styled.div`
  margin: 5px 0 3px 0;
  font-weight: bold;
  font-size: 18px;
`;

const Comment = styled.div`
  margin-top: 5px;
  font-weight: bold;
`;

export default SpvDividend;
