import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import Home from './Components/Home';
import BillReport from './Components/BillReport';
import BillPreparingReport from './Components/BillPreparingReport';
import InvestmentReport from './Components/InvestmentReport';
import ShareholderInvestmentReport from './Components/ShareholderInvestmentReport';
import SpvProfitSummaryReport from './Components/SpvProfitSummaryReport';
import BillSummaryForOwner from './Components/BillSummaryForOwner';
import BillSummaryForOwnerApli from './Components/BillSummaryForOwnerApli';
import BillSummaryForOwnerSyncMIS from './Components/BillSummaryForOwner/indexSyncMIS';
import BillPayment from './Components/BillPayment';
import BillPaymentSyncMIS from './Components/BillPayment/indexSyncMIS';
import BillInvoice from './Components/BillInvoice';
import BillInvoiceSyncMIS from './Components/BillInvoice/indexSyncMIS';
import FixedProfitInvoice from './Components/FixedProfitInvoice';
import AnnualPowerInvoice from './Components/AnnualPowerInvoice';
import AnnualPowerPayment from './Components/AnnualPowerPayment';
import SpvDividend from './Components/SpvDividend';

// The Main component renders one of the three provided
// Routes (provided that one matches). Both the /roster
// and /schedule routes will match any pathname that starts
// with /roster or /schedule. The / route will only match
// when the pathname is exactly the string "/"
const App = (props) => (
  <main>
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route
          path="/BillReport/:billId"
          render={(routeProps) => <BillReport billId={routeProps.match.params.billId} {...props} />}
        />
        <Route
          path="/BillPreparingReport/:billId"
          render={(routeProps) => <BillPreparingReport billId={routeProps.match.params.billId} {...props} />}
        />
        <Route
          path="/InvestmentReport/:userId"
          render={(routeProps) => <InvestmentReport userId={routeProps.match.params.userId} {...props} />}
        />
        <Route
          path="/ShareholderInvestmentReport/:userId"
          render={(routeProps) => (
            <ShareholderInvestmentReport userId={routeProps.match.params.userId} {...props} />
          )}
        />
        <Route path="/SpvProfitSummaryReport" component={SpvProfitSummaryReport} />
        <Route
          path="/BillSummaryForOwner/:month"
          render={(routeProps) => <BillSummaryForOwner month={routeProps.match.params.month} {...props} />}
        />
        <Route
          path="/BillSummaryForOwnerApli/:month"
          render={(routeProps) => (
            <BillSummaryForOwnerApli month={routeProps.match.params.month} {...props} />
          )}
        />
        <Route
          path="/BillSummaryForOwnerSyncMIS/:month"
          render={(routeProps) => (
            <BillSummaryForOwnerSyncMIS month={routeProps.match.params.month} {...props} />
          )}
        />
        <Route
          path="/BillPayment/:month"
          render={(routeProps) => <BillPayment month={routeProps.match.params.month} {...props} />}
        />
        <Route
          path="/BillPaymentSyncMIS/:month"
          render={(routeProps) => <BillPaymentSyncMIS month={routeProps.match.params.month} {...props} />}
        />
        <Route
          path="/BillInvoice/:month"
          render={(routeProps) => <BillInvoice month={routeProps.match.params.month} {...props} />}
        />
        <Route
          path="/BillInvoiceSyncMIS/:month"
          render={(routeProps) => <BillInvoiceSyncMIS month={routeProps.match.params.month} {...props} />}
        />
        <Route path="/FixedProfitInvoice" component={FixedProfitInvoice} />
        <Route
          path="/AnnualPowerInvoice/:query"
          render={(routeProps) => <AnnualPowerInvoice query={routeProps.match.params.query} {...props} />}
        />
        <Route
          path="/AnnualPowerPayment/:query"
          render={(routeProps) => <AnnualPowerPayment query={routeProps.match.params.query} {...props} />}
        />
        <Route
          path="/SpvDividend/:companyId"
          render={(routeProps) => <SpvDividend companyId={routeProps.match.params.companyId} {...props} />}
        />
      </Switch>
    </BrowserRouter>
  </main>
);

export default App;
