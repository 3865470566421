const moment = require('moment');

//本金平均攤還法
const equalPrincipalPayment = ({ loanAmount, loanPeriod, loanRate, loanBeginDate, date }) => {
  const terms = loanPeriod * 12;
  const loanEnd = moment(loanBeginDate).add(loanPeriod, 'years');

  if (moment(date, 'YYYY-MM-DD').isBetween(loanBeginDate, loanEnd, null, '[)')) {
    let termIndex = moment(date, 'YYYY-MM-DD').diff(loanBeginDate, 'months');
    let termStart = moment(loanBeginDate, 'YYYY-MM-DD').add(termIndex, 'months');
    let termEnd = moment(loanBeginDate, 'YYYY-MM-DD').add(termIndex + 1, 'months');

    //當遇到繳費日是29~31, 而當期又是小月時, 必須將將此日計入下一期
    if (date === termEnd.format('YYYY-MM-DD')) {
      termIndex = termIndex + 1;
      termStart = moment(loanBeginDate, 'YYYY-MM-DD').add(termIndex, 'months');
      termEnd = moment(loanBeginDate, 'YYYY-MM-DD').add(termIndex + 1, 'months');
    }

    let principalPerTerm = Math.ceil(loanAmount / terms);
    const remainPrincipal = loanAmount - principalPerTerm * termIndex;
    const interest = Math.round((remainPrincipal * loanRate) / 12);
    const daysOfTerm = termEnd.diff(termStart, 'days');

    //最後一期的本金
    if (termIndex === terms - 1) {
      principalPerTerm = remainPrincipal;
    }

    const dayLoanCost = (principalPerTerm + interest) / daysOfTerm;
    return dayLoanCost;
  } else {
    return 0;
  }
};

//微電貸款本息計算方法: 每日攤還計息
const principalPaymentRFD = ({ loanAmount, loanPeriod, loanRate, loanBeginDate, date }) => {
  const loanEndDate = moment(loanBeginDate).add(loanPeriod, 'years');
  const currentDate = moment(date, 'YYYY-MM-DD');
  if (currentDate.isBetween(loanBeginDate, loanEndDate, null, '[)')) {
    const loanEndDate = moment(loanBeginDate).add(loanPeriod, 'years');
    const dayPrincipal = loanAmount / loanEndDate.diff(loanBeginDate, 'days');
    const remainPrincipal = loanAmount - dayPrincipal * currentDate.diff(loanBeginDate, 'days');
    const interest = (remainPrincipal * loanRate) / 365;

    const dayLoanCost = dayPrincipal + interest;
    return dayLoanCost;
  } else {
    return 0;
  }
};

const createYearCostList = (begin, years, cost) => {
  let list = [];
  for (let i = 0; i < years; i++) {
    const beginDate = moment(begin).add(i, 'years');
    const duration = 1;
    list.push({ beginDate, duration, cost });
  }

  return list;
};

const getRangeCost = (list, begin, end) => {
  if (!list) return 0;

  let counter = [];
  for (let i = 0; i < list.length; i++) {
    counter.push(0);
  }

  let day = moment(begin, 'YYYY-MM-DD');
  while (day.isBefore(end)) {
    for (let i = 0; i < list.length; i++) {
      const endDate = moment(list[i].beginDate)
        .add(list[i].duration, 'years')
        .format('YYYY-MM-DD');
      if (day.isBetween(list[i].beginDate, endDate, null, '[)')) {
        counter[i] += 1;
      }
    }
    day.add(1, 'days');
  }

  let totalCost = 0;
  for (let i = 0; i < list.length; i++) {
    const { beginDate, duration, cost } = list[i];
    const endDate = moment(beginDate)
      .add(duration, 'years')
      .format('YYYY-MM-DD');
    const days = moment(endDate, 'YYYY-MM-DD').diff(beginDate, 'days');
    totalCost += (cost * counter[i]) / days;
  }

  return totalCost;
};

const getRangeCostWithEnd = (list, begin, end) => {
  if (!list) return 0;

  let counter = [];
  for (let i = 0; i < list.length; i++) {
    counter.push(0);
  }

  let day = moment(begin, 'YYYY-MM-DD');
  while (day.isBefore(end)) {
    for (let i = 0; i < list.length; i++) {
      if (day.isBetween(list[i].beginDate, list[i].endDate, null, '[)')) {
        counter[i] += 1;
      }
    }
    day.add(1, 'days');
  }

  let totalCost = 0;
  for (let i = 0; i < list.length; i++) {
    const { beginDate, endDate, cost } = list[i];
    const days = moment(endDate, 'YYYY-MM-DD').diff(beginDate, 'days');
    totalCost += (cost * counter[i]) / days;
  }

  return totalCost;
};

const getRangeLoanCostRFD = (list, begin, end) => {
  if (!list) return 0;

  let totalLoanCost = 0;
  for (let i = 0; i < list.length; i++) {
    let loanCost = 0;
    const { amount, period, rate, beginDate } = list[i];
    let day = moment(begin);
    while (day.isBefore(end)) {
      loanCost += principalPaymentRFD({
        loanAmount: amount,
        loanPeriod: period,
        loanRate: rate,
        loanBeginDate: beginDate,
        date: day.format('YYYY-MM-DD'),
      });
      day.add(1, 'days');
    }
    totalLoanCost += loanCost;
  }

  return totalLoanCost;
};

module.exports = {
  equalPrincipalPayment,
  principalPaymentRFD,
  createYearCostList,
  getRangeCost,
  getRangeCostWithEnd,
  getRangeLoanCostRFD,
};
