import React, { Component } from 'react';
import axios from 'axios';
import moment from 'moment';
import styled from 'styled-components';
import { numberFormat, percentFormat } from '../Utils/Format';
import { equalPrincipalPayment } from '../Utils/Finance';

import './index.css';
import logoIcon from '../../svg/logo.svg';

class BillPreparingReport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      data: null,
      insuranceLoaded: false,
      insuranceData: null,
      loanFeeLoaded: false,
      loanFeeData: null,
      loanLoaded: false,
      loanData: null
    };
  }

  componentWillMount() {
    this.loadData();
    this.loadInsurance();
    this.loadLoanFee();
    this.loadLoan();
  }

  loadData = () => {
    if (this.props.billId) {
      axios.get('/api/v1/billPreparing?billId=' + this.props.billId).then(res => {
        if (res.data.status) {
          this.setState({
            data: res.data.result.bill,
            loaded: true
          });
        } else {
          this.setState({ loaded: true });
        }
      });
    }
  };

  loadInsurance = () => {
    if (this.props.billId) {
      axios.get('/api/v1/billPreparing/insurance?billId=' + this.props.billId).then(res => {
        if (res.data.status) {
          this.setState({
            insuranceData: res.data.result.insurances,
            insuranceLoaded: true
          });
        } else {
          this.setState({ insuranceLoaded: true });
        }
      });
    }
  };

  loadLoanFee = () => {
    if (this.props.billId) {
      axios.get('/api/v1/billPreparing/loanFee?billId=' + this.props.billId).then(res => {
        if (res.data.status) {
          this.setState({
            loanFeeData: res.data.result.loanFees,
            loanFeeLoaded: true
          });
        } else {
          this.setState({ loanFeeLoaded: true });
        }
      });
    }
  };

  loadLoan = () => {
    if (this.props.billId) {
      axios.get('/api/v1/billPreparing/loan?billId=' + this.props.billId).then(res => {
        if (res.data.status) {
          this.setState({
            loanData: res.data.result.loans,
            loanLoaded: true
          });
        } else {
          this.setState({ loanLoaded: true });
        }
      });
    }
  };

  rentFormula = data => {
    let formula = '';
    if (data.rentYearCost) {
      const billDays = moment(data.rangeEnd).diff(data.rangeBegin, 'days');
      const yearDays = moment(data.rangeBegin).isLeapYear() ? 366 : 365;

      formula = `${numberFormat({
        value: data.rentYearCost,
        postfix: '元'
      })} × ${billDays} / ${yearDays}`;
    }

    if (data.rentRateByIncome) {
      if (data.rentWithTax) {
        formula = `(${numberFormat({
          value: data.incomeWithTax,
          postfix: '元'
        })} - ${numberFormat({ value: data.meterRentCost, postfix: '元' })}) × ${percentFormat(
          data.rentRateByIncome
        )}`;
      } else {
        formula = `(${numberFormat({
          value: data.incomeWithTax,
          postfix: '元'
        })} / 1.05) × ${percentFormat(data.rentRateByIncome)}`;
      }
    }

    return formula;
  };

  rentDesc = data => {
    let desc = '';
    if (data.rentYearCost) {
      desc = `每年租金 ${numberFormat({ value: data.rentYearCost, postfix: '元' })}`;
    }

    if (data.rentRateByIncome) {
      if (data.rentWithTax) {
        desc = `(含稅電費-電表租費) × ${percentFormat(data.rentRateByIncome)}`;
      } else {
        desc = `未稅電費 × ${percentFormat(data.rentRateByIncome)}`;
      }
    }

    return desc;
  };

  operationFormula = data => {
    const billDays = moment(data.rangeEnd).diff(data.rangeBegin, 'days');
    const yearDays = moment(data.rangeBegin).isLeapYear() ? 366 : 365;

    let formula = '';
    if (data.operationRateByCapital) {
      formula = `(${numberFormat({
        value: data.totalCapital,
        postfix: '元'
      })} × ${percentFormat(data.operationRateByCapital)}) × ${billDays} / ${yearDays}`;
    }

    if (data.operationRateByIncome) {
      formula = `(${numberFormat({
        value: data.incomeWithTax,
        postfix: '元'
      })} - ${numberFormat({ value: data.meterRentCost, postfix: '元' })}) × ${percentFormat(
        data.operationRateByIncome
      )}`;
    }

    return formula;
  };

  operationDesc = data => {
    let desc = '';
    if (data.operationRateByCapital) {
      desc = `每年系統總成本 × ${percentFormat(data.operationRateByCapital)}`;
    }

    if (data.operationRateByIncome) {
      desc = `(含稅電費-電表租費) × ${percentFormat(data.operationRateByIncome)}`;
    }

    return desc;
  };

  serviceFormula = data => {
    const formula = `(${numberFormat({
      value: data.incomeWithTax,
      postfix: '元'
    })} - ${numberFormat({ value: data.meterRentCost, postfix: '元' })}) × ${percentFormat(
      data.serviceRateByIncome
    )}`;

    return formula;
  };

  insuranceFormula = (data, insuranceData) => {
    const overlaps = insuranceData.filter(d => data.rangeBegin < d.endDate && data.rangeEnd > d.beginDate);
    const formula = this.getRangeCostWithEnd(overlaps, data.rangeBegin, data.rangeEnd).join(' + ');
    return formula;
  };

  insuranceDesc = (data, insuranceData) => {
    const overlaps = insuranceData.filter(d => data.rangeBegin < d.endDate && data.rangeEnd > d.beginDate);

    const list = [];
    for (let i = 0; i < overlaps.length; i++) {
      const d = overlaps[i];
      list.push(
        <div key={i}>{`${d.beginDate} 至 ${d.endDate}，費用 ${numberFormat({
          value: d.cost,
          postfix: '元'
        })}。`}</div>
      );
    }

    if (list.length === 0) {
      list.push('無');
    }

    return list;
  };

  loanFeeFormula = (data, loanFeeData) => {
    const overlaps = loanFeeData.filter(
      d =>
        data.rangeBegin <
          moment(d.beginDate)
            .add(d.duration, 'years')
            .format('YYYY-MM-DD') && data.rangeEnd > d.beginDate
    );
    const formula = this.getRangeCost(overlaps, data.rangeBegin, data.rangeEnd).join(' + ');
    return formula;
  };

  loanFeeDesc = (data, loanFeeData) => {
    const overlaps = loanFeeData.filter(
      d =>
        data.rangeBegin <
          moment(d.beginDate)
            .add(d.duration, 'years')
            .format('YYYY-MM-DD') && data.rangeEnd > d.beginDate
    );
    const list = [];
    for (let i = 0; i < overlaps.length; i++) {
      const d = overlaps[i];
      list.push(
        <div key={i}>{`${d.beginDate}起，總費用 ${numberFormat({
          value: d.cost,
          postfix: '元'
        })}，攤銷${d.duration}年。`}</div>
      );
    }

    if (list.length === 0) {
      list.push('無');
    }

    return list;
  };

  loanFormula = (data, loanData) => {
    const overlaps = loanData.filter(
      d =>
        data.rangeBegin <
          moment(d.beginDate)
            .add(d.period, 'years')
            .format('YYYY-MM-DD') && data.rangeEnd > d.beginDate
    );
    const formula = this.getRangeLoanCost(overlaps, data.rangeBegin, data.rangeEnd).join(' + ');
    return formula;
  };

  loanDesc = (data, loanData) => {
    const overlaps = loanData.filter(
      d =>
        data.rangeBegin <
          moment(d.beginDate)
            .add(d.period, 'years')
            .format('YYYY-MM-DD') && data.rangeEnd > d.beginDate
    );
    const list = [];
    for (let i = 0; i < overlaps.length; i++) {
      const d = overlaps[i];
      list.push(
        <div key={i}>{`${d.beginDate}起，貸${d.period}年，利率 ${percentFormat(d.rate)}，金額 ${numberFormat({
          value: d.amount,
          postfix: '元'
        })}。`}</div>
      );
    }

    if (list.length === 0) {
      list.push('無');
    }

    return list;
  };

  getRangeCost = (list, begin, end) => {
    let counter = [];
    for (let i = 0; i < list.length; i++) {
      counter.push(0);
    }

    let day = moment(begin, 'YYYY-MM-DD');
    while (day.isBefore(end)) {
      for (let i = 0; i < list.length; i++) {
        const endDate = moment(list[i].beginDate)
          .add(list[i].duration, 'years')
          .format('YYYY-MM-DD');
        if (day.isBetween(list[i].beginDate, endDate, null, '[)')) {
          counter[i] += 1;
        }
      }
      day.add(1, 'days');
    }

    let costList = [];
    for (let i = 0; i < list.length; i++) {
      const { beginDate, duration, cost } = list[i];
      const endDate = moment(beginDate)
        .add(duration, 'years')
        .format('YYYY-MM-DD');
      const days = moment(endDate, 'YYYY-MM-DD').diff(beginDate, 'days');
      costList.push(`(${numberFormat({ value: cost, postfix: '元' })} × ${counter[i]} / ${days})`);
    }

    return costList;
  };

  getRangeCostWithEnd = (list, begin, end) => {
    let counter = [];
    for (let i = 0; i < list.length; i++) {
      counter.push(0);
    }

    let day = moment(begin, 'YYYY-MM-DD');
    while (day.isBefore(end)) {
      for (let i = 0; i < list.length; i++) {
        if (day.isBetween(list[i].beginDate, list[i].endDate, null, '[)')) {
          counter[i] += 1;
        }
      }
      day.add(1, 'days');
    }

    let costList = [];
    for (let i = 0; i < list.length; i++) {
      const { beginDate, endDate, cost } = list[i];
      const days = moment(endDate, 'YYYY-MM-DD').diff(beginDate, 'days');
      costList.push(`(${numberFormat({ value: cost, postfix: '元' })} × ${counter[i]} / ${days})`);
    }

    return costList;
  };

  getRangeLoanCost = (list, begin, end) => {
    let loanCostList = [];
    for (let i = 0; i < list.length; i++) {
      const { amount, period, rate, beginDate } = list[i];
      let day = moment(begin);
      let loanCost = 0;
      let dayCount = 0;
      while (day.isBefore(end)) {
        const cost = equalPrincipalPayment({
          loanAmount: amount,
          loanPeriod: period,
          loanRate: rate,
          loanBeginDate: beginDate,
          date: day.format('YYYY-MM-DD')
        });
        if (cost) {
          dayCount += 1;
        }

        loanCost += cost;
        day.add(1, 'days');
      }

      loanCostList.push(
        `${numberFormat({
          value: Math.round(loanCost),
          postfix: '元'
        })} (${dayCount}天本息) [貸${numberFormat({
          value: amount
        })}元]`
      );
    }
    return loanCostList;
  };

  render() {
    const { data, insuranceData, loanFeeData, loanData } = this.state;
    const viewData =
      !data || !insuranceData || !loanFeeData || !loanData
        ? {}
        : {
            reportDate: moment().format('YYYY-MM-DD'),
            owner: data.owner,
            serialNo: ('00000000' + data.id).slice(-8),
            billBeginDate: data.rangeBegin,
            billEndDate: moment(data.rangeEnd)
              .subtract(1, 'days')
              .format('YYYY-MM-DD'),
            billDays: `${moment(data.rangeEnd).diff(data.rangeBegin, 'days')} 天`,
            projectCode: data.projectCode,
            pvId: data.pvId,
            pvName: data.pvName,
            solaName: data.solaName,
            billPower: numberFormat({ value: data.totalPower, postfix: '度' }),
            billIncome: numberFormat({ value: data.incomeWithTax, postfix: '元' }),
            dispatchToA: numberFormat({
              value:
                data.meterRentCost +
                data.rentCostFixed +
                data.rentCostFloat +
                data.operateCostFixed +
                data.operateCostFloat +
                data.serviceCostFloat +
                data.insuranceCost +
                data.loanCost +
                data.loanFeeCost +
                data.businessTax,
              postfix: '元'
            }),
            dispatchToB: numberFormat({ value: data.profit, postfix: '元' }),
            meterRentCost: numberFormat({ value: data.meterRentCost, postfix: '元' }),
            rentCost: numberFormat({ value: data.rentCostFixed + data.rentCostFloat, postfix: '元' }),
            rentFormula: this.rentFormula(data),
            rentDesc: this.rentDesc(data),
            operationCost: numberFormat({
              value: data.operateCostFixed + data.operateCostFloat,
              postfix: '元'
            }),
            operationFormula: this.operationFormula(data),
            operationDesc: this.operationDesc(data),
            serviceCost: numberFormat({ value: data.serviceCostFloat, postfix: '元' }),
            serviceFormula: this.serviceFormula(data),
            serviceRate: percentFormat(data.serviceRateByIncome),
            insuranceCost: numberFormat({ value: data.insuranceCost, postfix: '元' }),
            insuranceFormula: this.insuranceFormula(data, insuranceData),
            insuranceDesc: this.insuranceDesc(data, insuranceData),
            loanCost: numberFormat({ value: data.loanCost, postfix: '元' }),
            loanFormula: this.loanFormula(data, loanData),
            loanDesc: this.loanDesc(data, loanData),
            loanFeeCost: numberFormat({ value: data.loanFeeCost, postfix: '元' }),
            loanFeeFormula: this.loanFeeFormula(data, loanFeeData),
            loanFeeDesc: this.loanFeeDesc(data, loanFeeData)
            //businessTax: numberFormat({value:data.businessTax})
          };

    if (
      !this.state.loaded ||
      !this.state.insuranceLoaded ||
      !this.state.loanFeeLoaded ||
      !this.state.loanLoaded
    ) {
      return null;
    } else if (!viewData.reportDate) {
      return <h2>查無資料</h2>;
    } else {
      return (
        <div>
          <div className="bill-preparing-report">
            <div className="logo">
              <img alt="logoIcon" src={logoIcon} />
            </div>
            <div className="header">{viewData.pvName} 電費拆帳預審報表</div>
            <Container>
              <table cellPadding="0" cellSpacing="0">
                <tbody>
                  <tr>
                    <td className="title">報表資訊</td>
                  </tr>
                  <tr>
                    <td>電廠名稱</td>
                    <td>{viewData.pvName}</td>
                  </tr>
                  <tr>
                    <td>電廠專案代號</td>
                    <td>{viewData.projectCode}</td>
                  </tr>
                  <tr>
                    <td>收益權名稱</td>
                    <td>{viewData.solaName}</td>
                  </tr>
                  <tr>
                    <td>收益權代號</td>
                    <td>{viewData.pvId}</td>
                  </tr>
                  <tr>
                    <td>報表流水號</td>
                    <td>{viewData.serialNo}</td>
                  </tr>
                  <tr>
                    <td>報表產生日</td>
                    <td>{viewData.reportDate}</td>
                  </tr>
                </tbody>
              </table>
              <table cellPadding="0" cellSpacing="0">
                <tbody>
                  <tr>
                    <td className="title">電費單內容</td>
                  </tr>
                  <tr>
                    <td>計費期間</td>
                    <td>
                      {viewData.billBeginDate} 至 {viewData.billEndDate}
                    </td>
                  </tr>
                  <tr>
                    <td>計費天數</td>
                    <td>{viewData.billDays}</td>
                  </tr>
                  <tr>
                    <td>計費度數</td>
                    <td>{viewData.billPower}</td>
                  </tr>
                  <tr>
                    <td>含稅電費</td>
                    <td>{viewData.billIncome}</td>
                  </tr>
                </tbody>
              </table>
            </Container>
            <table cellPadding="0" cellSpacing="0">
              <tbody>
                <tr>
                  <td className="title">費用條件</td>
                </tr>
                <tr>
                  <td width="20%">保險費</td>
                  <td>{viewData.insuranceDesc}</td>
                </tr>
                <tr>
                  <td width="20%">貸款</td>
                  <td>{viewData.loanDesc}</td>
                </tr>
                {/*}
                <tr>
                  <td width="20%">貸款手續費</td>
                  <td>{viewData.loanFeeDesc}</td>
                </tr>
              */}
              </tbody>
            </table>
            <table className="detail" cellPadding="0" cellSpacing="0">
              <tbody>
                <tr>
                  <td className="title">拆帳明細</td>
                </tr>
                <tr className="field">
                  <td>明細</td>
                  <td>金額</td>
                  <td>說明</td>
                  <td>計算方式</td>
                </tr>
                <tr>
                  <td>A.電表租費</td>
                  <td>{viewData.meterRentCost}</td>
                  <td>依照電費單</td>
                  <td />
                </tr>
                <tr>
                  <td>B.租金費用</td>
                  <td>{viewData.rentCost}</td>
                  <td>{viewData.rentDesc}</td>
                  <td>{viewData.rentFormula}</td>
                </tr>
                <tr>
                  <td>C.營運維修費</td>
                  <td>{viewData.operationCost}</td>
                  <td>{viewData.operationDesc}</td>
                  <td>{viewData.operationFormula}</td>
                </tr>
                <tr>
                  <td>D.索拉服務費</td>
                  <td>{viewData.serviceCost}</td>
                  <td>(含稅電費-電表租費) × {viewData.serviceRate}</td>
                  <td>{viewData.serviceFormula}</td>
                </tr>
                <tr>
                  <td>E.保險費用</td>
                  <td>{viewData.insuranceCost}</td>
                  <td>實際保險費按日攤還</td>
                  <td>{viewData.insuranceFormula}</td>
                </tr>
                <tr>
                  <td>F.貸款本息費用</td>
                  <td>{viewData.loanCost}</td>
                  <td>實際貸款每期本息按日攤還</td>
                  <td>{viewData.loanFormula}</td>
                </tr>
                {/*
                <tr>
                  <td>G.貸款手續費用</td>
                  <td>{viewData.loanFeeCost}</td>
                  <td>實際貸款手續費按日攤還</td>
                  <td>{viewData.loanFeeFormula}</td>
                </tr>
                /*}
                {/*}
              <tr>
                <td>營業稅</td>
                <td>{viewData.businessTax} 元</td>
                <td>營業稅預留</td>
              </tr>
            */}
                <tr>
                  <td colSpan="4">
                    ----------------------------------------------------------------------------------------------------------------------------------------------------
                  </td>
                </tr>
                <tr>
                  <td>成本總計</td>
                  <td>{viewData.dispatchToA}</td>
                  <td>
                    <div>電廠營運成本</div>
                    <div>應撥款給{viewData.owner}</div>
                  </td>
                  <td>A+B+C+D+E+F</td>
                </tr>
                <tr>
                  <td>收益總計</td>
                  <td>{viewData.dispatchToB}</td>
                  <td>
                    <div>電廠營運收益</div>
                    <div>應撥款給收益權所屬人</div>
                  </td>
                  <td>含稅電費 - 成本總計</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      );
    }
  }
}

const Container = styled.div`
  display: flex;
`;

export default BillPreparingReport;
