import React, { Component } from 'react';
import axios from 'axios';
import moment from 'moment';

import './index.css';
import logoIcon from '../../svg/logo.svg';

const hideName = name => {
  return name;
  /*
  const newName = name.substr(0, 1) + 'O' + name.substr(2);
  return newName;
  */
};

class SpvProfitSummaryReport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      companyName: '',
      beginDate: '',
      endDate: '',
      cost: '',
      profitByPv: [],
      profitByHolder: []
    };
  }

  componentDidMount() {
    if (this.props.location.search) {
      axios.get(`/api/v1/spvProfit/summary${this.props.location.search}`).then(res => {
        if (res.data && res.data.status) {
          this.setState({
            companyName: res.data.result.companyName,
            beginDate: res.data.result.beginDate,
            endDate: res.data.result.endDate,
            cost: res.data.result.cost,
            profitByPv: res.data.result.profitByPv,
            profitByHolder: res.data.result.profitByHolder,
            totalProfit: res.data.result.totalProfit
          });
        }
      });
    }
  }

  render() {
    const { companyName, beginDate, endDate, cost, profitByPv, profitByHolder, totalProfit } = this.state;

    let profitByPvDOM = [];
    if (profitByPv && profitByPv.length > 0) {
      profitByPv.forEach((profit, index) => {
        profitByPvDOM.push(
          <table id={'pv_' + profit.pvId} className="pvTable" key={index}>
            <caption>
              {profit.pvName} ({profit.pvId})
            </caption>
            <tbody>
              <tr>
                <th>電廠開始發電日期</th>
                <td>{profit.pvValidityDate}</td>
                <th>電廠容量</th>
                <td>{profit.pvCapacity} 瓩</td>
              </tr>
              <tr>
                <th>總收益(T)</th>
                <td>{profit.moneyOfBill} 元</td>
                <th>分攤成本(C)</th>
                <td>{profit.moneyOfCost} 元</td>
              </tr>
              <tr>
                <th>可分配收益(T - C)</th>
                <td>{profit.moneyOfProfit} 元</td>
                <th>實際分配收益</th>
                <td>{profit.moneyOfAllocate} 元</td>
              </tr>
              <tr>
                <th colSpan="4">股東分潤</th>
              </tr>
              <tr>
                <td colSpan="4">
                  <table className="holderTable">
                    <tbody>
                      <tr>
                        <th>股東姓名</th>
                        <th>股利 (元)</th>
                        <th>投資金額 (元)</th>
                        <th>ROI</th>
                      </tr>
                      {profit.holderlist.length > 0 ? (
                        profit.holderlist.map((holder, index) => (
                          <tr key={index}>
                            <td>{hideName(holder.holderName)}</td>
                            <td>{holder.profit}</td>
                            <td>{holder.total}</td>
                            <td>{holder.ROI}</td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="4">
                            <span>無股東分潤</span>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        );
      });
    }

    let profitByHolderDOM = [];
    if (profitByHolder && profitByHolder.length > 0) {
      for (let i = 0; i < profitByHolder.length; i = i + 2) {
        const column1 = profitByHolder[i];
        const column2 = profitByHolder[i + 1];

        profitByHolderDOM.push(
          <tr key={i}>
            <td>{hideName(column1.holderName)}</td>
            <td>{column1.profit}</td>
            <td>{column1.deposit}</td>
            <td>{column1.ROI}</td>
            <td>{column2 ? hideName(column2.holderName) : ''}</td>
            <td>{column2 ? column2.profit : ''} </td>
            <td>{column2 ? column2.deposit : ''} </td>
            <td>{column2 ? column2.ROI : ''} </td>
          </tr>
        );
      }
    }

    return (
      <div>
        <div className="spv-report">
          <div className="logo">
            <img alt="logoIcon" src={logoIcon} />
          </div>
          <div className="header">{companyName}_分潤總表</div>
          <table id="baseInfo" className="baseTable">
            <caption>分潤資訊</caption>
            <tbody>
              <tr>
                <th>報表產生日</th>
                <td>{moment().format('YYYY-MM-DD')}</td>
                <th>報表產出單位</th>
                <td>索拉能源股份有限公司</td>
              </tr>
              <tr>
                <th>分潤區間</th>
                <td>
                  {beginDate} 至 {endDate}
                </td>
                <th>營運成本</th>
                <td>{cost} 元</td>
              </tr>
              <tr>
                <th>總收益</th>
                <td>{totalProfit} 元</td>
              </tr>
            </tbody>
          </table>
          <table id="shareholderInfo" className="shareholderTable">
            <caption>股東總分潤列表</caption>
            <tbody>
              <tr>
                <th>姓名</th>
                <th>股利 (元)</th>
                <th>投資 (元)</th>
                <th>ROI</th>
                <th>姓名</th>
                <th>股利 (元)</th>
                <th>投資 (元)</th>
                <th>ROI</th>
              </tr>
              {profitByHolder.length > 0 ? (
                profitByHolderDOM
              ) : (
                <tr>
                  <td colSpan="4">
                    <span>無股東分潤</span>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {profitByPvDOM.map((dom, index) => (
          <div className="spv-report" key={index}>
            <div className="logo">
              <img alt="logoIcon" src={logoIcon} />
            </div>
            <div className="header">
              {companyName}_分潤明細{index + 1}/{profitByPvDOM.length}
            </div>
            {dom}
          </div>
        ))}
      </div>
    );
  }
}

export default SpvProfitSummaryReport;
