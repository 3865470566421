import React, { Component } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { numberFormat, percentFormat } from '../Utils/Format';

import './index.css';
import logoIcon from '../../svg/logo.svg';

class BillInvoiceSyncMIS extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            repay: [],
            compensation: [],
            comment: [],
            data: null,
        };
    }

    componentWillMount() {
        this.loadData();
    }

    loadData = () => {
        (async () => {
            await this.loadRepay();
            await this.loadCompensation();
            await this.loadComment();
            await this.loadBill();
        })();
    };

    loadRepay = async () => {
        const res = await axios.get('/api/v1/pvAccount/repay?month=' + this.props.month);
        if (res.data.status) {
            const { repay } = res.data.result;
            this.setState({ repay });
            //console.log('repay', repay);
        }
    };

    loadCompensation = async () => {
        const res = await axios.get('/api/v1/pvAccount/compensation?month=' + this.props.month);
        if (res.data.status) {
            const { compensation } = res.data.result;
            this.setState({ compensation });
            //console.log('compensation', compensation);
        }
    };

    loadComment = async () => {
        const res = await axios.get('/api/v1/BillInvoice/comment?month=' + this.props.month);
        if (res.data.status) {
            const { comment } = res.data.result;
            this.setState({ comment });
            //console.log('comment', comment);
        }
    };

    loadBill = async () => {
        const res = await axios.get('/api/v1/BillInvoice/syncMis?month=' + this.props.month);
        if (res.data.status) {
            const { invoice } = res.data.result;
            let lastBeneficiary = '',
                lastCompany = '';
            let beneficiary, company;
            let list = [];
            let commentIndex = 0;
            invoice.forEach((elm) => {
                if (lastBeneficiary !== elm.beneficiary) {
                    beneficiary = {
                        name: elm.beneficiary,
                        companyList: [],
                        sum: 0,
                    };
                    list.push(beneficiary);
                    lastBeneficiary = elm.beneficiary;
                    lastCompany = '';
                    commentIndex = 0;
                }

                if (lastCompany !== elm.owner) {
                    company = {
                        name: elm.owner,
                        pvList: [],
                        commentList: [],
                    };
                    beneficiary.companyList.push(company);
                    lastCompany = elm.owner;
                }

                elm.profit =
                    elm.currentProfit + elm.lastAccumulation > 0 ? elm.currentProfit + elm.lastAccumulation : 0;
                beneficiary.sum += elm.profit;
                company.pvList.push(elm);

                const compensation = this.state.compensation.filter(
                    (e) => e.pvId === elm.pvId && e.userId === elm.userId
                );
                compensation.forEach((e) => {
                    commentIndex = commentIndex + 1;
                    const row = { name: e.name, profit: e.profit, commentIndex };
                    company.pvList.push(row);
                    company.commentList.push(`附註${commentIndex}: ${e.reason}`);
                    beneficiary.sum += row.profit;
                });

                const repay = this.state.repay.filter((e) => e.pvId === elm.pvId && e.userId === elm.userId);
                repay.forEach((e) => {
                    commentIndex = commentIndex + 1;
                    const row = { name: e.name, profit: e.profit, commentIndex };
                    company.pvList.push(row);
                    company.commentList.push(`附註${commentIndex}: ${e.reason}${e.comment ? e.comment : ''}`);
                    beneficiary.sum += row.profit;
                });

                this.state.comment.forEach((e) => {
                    if (e.pvId === elm.pvId) {
                        commentIndex = commentIndex + 1;
                        company.commentList.push(`附註${commentIndex}: ${e.content}`);
                    }
                });
            });

            console.log('list', list);

            this.setState({
                data: list,
                loaded: true,
            });
        } else {
            this.setState({ loaded: true });
        }
    };

    render() {
        const { data } = this.state;
        let content = [];
        if (data) {
            data.forEach((beneficiary, i) => {
                content.push(
                    <div className="bill-invoice" key={i}>
                        <div className="logo">
                            <img alt="logoIcon" src={logoIcon} />
                        </div>
                        <div className="header">
                            {beneficiary.name}_電費對帳單 ({this.props.month})
            </div>
                        <Content>
                            <div>
                                <table>
                                    <tbody>
                                        <tr>
                                            <th>營運公司</th>
                                            <th>案場</th>
                                            <th colSpan="2">電費單計費起訖</th>
                                            <th>電費</th>
                                            <th>電表費</th>
                                            <th>租金</th>
                                            <th>保險費</th>
                                            <th>貸款本息</th>
                                            <th>營運費</th>
                                            <th>服務費</th>
                                            <th>收益</th>
                                            <th>佔比</th>
                                            <th>應得收益</th>
                                            <th>上期損益</th>
                                            <th>入帳金額</th>
                                        </tr>
                                        {beneficiary.companyList.map((company, j) =>
                                            company.pvList.map((pv, k) => (
                                                <tr key={j * 10 + k}>
                                                    {k === 0 ? <td rowSpan={company.pvList.length}>{company.name}</td> : null}
                                                    <td>{pv.name}</td>
                                                    {pv.begin ? (
                                                        <td className="date">{pv.begin}</td>
                                                    ) : (
                                                        <td className="date" colSpan={13}>{`參考附註${pv.commentIndex}`}</td>
                                                    )}
                                                    {pv.begin ? <td className="date">{pv.end}</td> : null}
                                                    {pv.begin ? <td className="number">{numberFormat({ value: pv.income })}</td> : null}
                                                    {pv.begin ? <td className="number">{numberFormat({ value: pv.meter })}</td> : null}
                                                    {pv.begin ? <td className="number">{numberFormat({ value: pv.rent })}</td> : null}
                                                    {pv.begin ? (
                                                        <td className="number">{numberFormat({ value: pv.insurance })}</td>
                                                    ) : null}
                                                    {pv.begin ? <td className="number">{numberFormat({ value: pv.loan })}</td> : null}
                                                    {pv.begin ? (
                                                        <td className="number">{numberFormat({ value: pv.operate })}</td>
                                                    ) : null}
                                                    {pv.begin ? (
                                                        <td className="number">{numberFormat({ value: pv.service })}</td>
                                                    ) : null}
                                                    {pv.begin ? (
                                                        <td className="number">{numberFormat({ value: pv.totalProfit })}</td>
                                                    ) : null}
                                                    {pv.begin ? (
                                                        <td className="number">{percentFormat(parseFloat(pv.percent).toFixed(4))}</td>
                                                    ) : null}
                                                    {pv.begin ? (
                                                        <td className="number">{numberFormat({ value: pv.currentProfit })}</td>
                                                    ) : null}
                                                    {pv.begin ? (
                                                        <td className="number">{numberFormat({ value: pv.lastAccumulation })}</td>
                                                    ) : null}
                                                    <td className="number">{numberFormat({ value: pv.profit })}</td>
                                                </tr>
                                            ))
                                        )}
                                        <tr>
                                            <td className="number" colSpan={16}>
                                                本期應入帳總金額： {numberFormat({ value: beneficiary.sum })}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <Comment>{`附註: 佔比最多顯示小數點後兩位，僅做為參考用，如要驗算，誤差小於0.005%皆屬正常。`}</Comment>
                                {beneficiary.companyList.map((company, j) =>
                                    company.commentList.map((reason, k) => <Comment key={j * 10 + k}>{reason}</Comment>)
                                )}
                            </div>
                            <SignContainer>
                                <Block>
                                    <Sign>(章)</Sign>
                                    <span>
                                        <div>[報表代理人]</div>
                                        <div>索拉能源股份有限公司</div>
                                        <div>日期:</div>
                                    </span>
                                </Block>
                                <Block>
                                    <Sign>(章)</Sign>
                                    <span>
                                        <div>[受益人]</div>
                                        <div>{beneficiary.name}</div>
                                        <div>日期:</div>
                                    </span>
                                </Block>
                            </SignContainer>
                        </Content>
                    </div>
                );
            });
        }
        return <div>{content}</div>;
    }
}

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 665px;
`;

const Comment = styled.div`
  margin-top: 5px;
  font-weight: bold;
`;

const SignContainer = styled.div`
  display: flex;
`;

const Block = styled.div`
  display: flex;
  width: 50%;
`;

const Sign = styled.div`
  height: 125px;
  width: 200px;
  margin-right: 15px;
  color: lightgrey;
  border: 1px dashed lightgrey;
`;

export default BillInvoiceSyncMIS;
