import React, { Component } from 'react';
import axios from 'axios';
import moment from 'moment';
import { numberFormat, percentFormat } from '../Utils/Format';

import './index.css';
import logoIcon from '../../svg/logo.svg';

class BillReport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      data: null,
      profitList: null
    };
  }

  componentWillMount() {
    if (this.props.billId) {
      axios.get('/api/v1/bill?billId=' + this.props.billId).then(res1 => {
        if (res1.data.status) {
          axios.get('/api/v1/bill/profitList?billId=' + this.props.billId).then(res2 => {
            if (res2.data.status) {
              this.setState({
                data: res1.data.result.bill,
                profitList: res2.data.result.profitList,
                loaded: true
              });
            } else {
              this.setState({ loaded: true });
            }
          });
        } else {
          this.setState({ loaded: true });
        }
      });
    }
  }

  rentDesc = data => {
    let desc = '';
    if (data.rentYearCost) {
      desc = `每年租金 ${numberFormat({ value: data.rentYearCost, postfix: '元' })}`;
    }

    if (data.rentRateByIncome) {
      if (data.rentWithTax) {
        desc = `(含稅電費-電表租費) × ${percentFormat(data.rentRateByIncome)}`;
      } else {
        desc = `未稅電費 × ${percentFormat(data.rentRateByIncome)}`;
      }
    }

    return desc;
  };

  operationDesc = data => {
    let desc = '';
    if (data.operationRateByCapital) {
      desc = `每年系統總成本 × ${percentFormat(data.operationRateByCapital)}`;
    }

    if (data.operationRateByIncome) {
      desc = `(含稅電費-電表租費) × ${percentFormat(data.operationRateByIncome)}`;
    }

    return desc;
  };

  render() {
    const { data, profitList } = this.state;
    const viewData = !data
      ? {}
      : {
          reportDate: moment().format('YYYY-MM-DD'),
          owner: data.owner,
          serialNo: ('00000000' + data.id).slice(-8),
          billBeginDate: data.rangeBegin,
          billEndDate: moment(data.rangeEnd)
            .subtract(1, 'days')
            .format('YYYY-MM-DD'),
          billDays: `${moment(data.rangeEnd).diff(data.rangeBegin, 'days')} 天`,
          projectCode: data.projectCode,
          pvId: data.pvId,
          pvName: data.pvName,
          solaName: data.solaName,
          billPower: numberFormat({ value: data.totalPower, postfix: '度' }),
          billIncome: numberFormat({ value: data.incomeWithTax, postfix: '元' }),
          dispatchToA: numberFormat({
            value:
              data.meterRentCost +
              data.rentCostFixed +
              data.rentCostFloat +
              data.operateCostFixed +
              data.operateCostFloat +
              data.serviceCostFloat +
              data.insuranceCost +
              data.loanCost +
              data.loanFeeCost +
              data.businessTax,
            postfix: '元'
          }),
          dispatchToB: numberFormat({ value: data.profit, postfix: '元' }),
          meterRentCost: numberFormat({ value: data.meterRentCost, postfix: '元' }),
          rentCost: numberFormat({ value: data.rentCostFixed + data.rentCostFloat, postfix: '元' }),
          rentDesc: this.rentDesc(data),
          operationCost: numberFormat({
            value: data.operateCostFixed + data.operateCostFloat,
            postfix: '元'
          }),
          operationDesc: this.operationDesc(data),
          serviceCost: numberFormat({ value: data.serviceCostFloat, postfix: '元' }),
          serviceRate: percentFormat(data.serviceRateByIncome),
          insuranceCost: numberFormat({ value: data.insuranceCost, postfix: '元' }),
          loanCost: numberFormat({ value: data.loanCost, postfix: '元' }),
          loanFeeCost: numberFormat({ value: data.loanFeeCost, postfix: '元' })
          //businessTax: numberFormat({value:data.businessTax).format('0,0')
        };

    if (!this.state.loaded) {
      return null;
    } else if (!viewData.reportDate) {
      return <h2>查無資料</h2>;
    } else {
      let distributeReportList = [];
      for (let i = 0; i < 2; i++) {
        distributeReportList.push(
          <div className="bill-report">
            <div className="logo">
              <img alt="logoIcon" src={logoIcon} />
            </div>
            <div className="header">{viewData.pvName}_電費拆帳報表</div>
            <table cellPadding="0" cellSpacing="0">
              <tbody>
                <tr>
                  <td className="title">報表資訊</td>
                </tr>
                <tr>
                  <td>報表流水號</td>
                  <td colSpan="3">
                    {viewData.serialNo}_{i === 0 ? 'a' : 'b'}
                  </td>
                </tr>
                <tr>
                  <td>報表產生日</td>
                  <td colSpan="3">{viewData.reportDate}</td>
                </tr>
                <tr>
                  <td>電廠名稱</td>
                  <td colSpan="3">{viewData.pvName}</td>
                </tr>
                <tr>
                  <td>電廠專案代號</td>
                  <td colSpan="3">{viewData.projectCode}</td>
                </tr>
                <tr>
                  <td>收益權名稱</td>
                  <td colSpan="3">{viewData.solaName}</td>
                </tr>
                <tr>
                  <td>收益權代號</td>
                  <td colSpan="3">{viewData.pvId}</td>
                </tr>
                <tr>
                  <td className="title">電費單內容</td>
                </tr>
                <tr>
                  <td>計費期間</td>
                  <td colSpan="3">
                    {viewData.billBeginDate} 至 {viewData.billEndDate}
                  </td>
                </tr>
                <tr>
                  <td>計費天數</td>
                  <td colSpan="3">{viewData.billDays}</td>
                </tr>
                <tr>
                  <td>計費度數</td>
                  <td colSpan="3">{viewData.billPower}</td>
                </tr>
                <tr>
                  <td>含稅電費</td>
                  <td>{viewData.billIncome}</td>
                </tr>
                <tr>
                  <td className="title">拆帳明細</td>
                </tr>
                <tr className="field">
                  <td>明細</td>
                  <td>金額</td>
                  <td>備註</td>
                </tr>
                <tr>
                  <td>A.電表租費</td>
                  <td>{viewData.meterRentCost}</td>
                  <td>依照電費單</td>
                </tr>
                <tr>
                  <td>B.租金費用</td>
                  <td>{viewData.rentCost}</td>
                  <td>{viewData.rentDesc}</td>
                </tr>
                <tr>
                  <td>C.營運維修費</td>
                  <td>{viewData.operationCost}</td>
                  <td>{viewData.operationDesc}</td>
                </tr>
                <tr>
                  <td>D.索拉服務費</td>
                  <td>{viewData.serviceCost}</td>
                  <td>(含稅電費-電表租費) × {viewData.serviceRate}</td>
                </tr>
                <tr>
                  <td>E.保險費用</td>
                  <td>{viewData.insuranceCost}</td>
                  <td>實際保險費按日攤還</td>
                </tr>
                <tr>
                  <td>F.貸款本息費用</td>
                  <td>{viewData.loanCost}</td>
                  <td>實際貸款每期本息按日攤還</td>
                </tr>
                <tr>
                  <td>G.貸款手續費用</td>
                  <td>{viewData.loanFeeCost}</td>
                  <td>實際貸款手續費按日攤還</td>
                </tr>
                {/*}
              <tr>
                <td>營業稅</td>
                <td>{viewData.businessTax} 元</td>
                <td>營業稅預留</td>
              </tr>
            */}
                <tr>
                  <td colSpan="3">
                    -----------------------------------------------------------------------------------------------------------------------------------------------
                  </td>
                </tr>
                <tr>
                  <td>成本總計</td>
                  <td>{viewData.dispatchToA}</td>
                  <td>
                    <div>電廠營運成本</div>
                    <div>應撥款給{viewData.owner}</div>
                  </td>
                </tr>
                <tr>
                  <td>收益總計</td>
                  <td>{viewData.dispatchToB}</td>
                  <td>
                    <div>電廠營運收益</div>
                    <div>應撥款給收益權所屬人</div>
                  </td>
                </tr>
              </tbody>
            </table>
            <table className="sign" cellPadding="0" cellSpacing="0">
              <tbody>
                <tr>
                  <td className="title">報表簽核</td>
                </tr>
                <tr className="field">
                  <td>簽核者</td>
                  <td>蓋章</td>
                  <td>日期</td>
                  <td>備註</td>
                </tr>
                <tr className="sign">
                  <td>索拉能源股份有限公司</td>
                  <td>(章)</td>
                  <td />
                  <td>報表代理人</td>
                </tr>
                <tr className="sign">
                  <td>{viewData.owner}</td>
                  <td>(章)</td>
                  <td />
                  <td>電廠持有人</td>
                </tr>
              </tbody>
            </table>
          </div>
        );
      }

      const serials = ['a', 'b', 'c'];
      let profitReportList = [];
      for (let i = 0; i < profitList.length; i++) {
        const item = profitList[i];
        for (let j = 0; j < 3; j++) {
          profitReportList.push(
            <div className="bill-report">
              <div className="logo">
                <img alt="logoIcon" src={logoIcon} />
              </div>
              <div className="header">{viewData.pvName}_收益分潤報表</div>
              <table cellPadding="0" cellSpacing="0">
                <tbody>
                  <tr>
                    <td className="title">報表資訊</td>
                  </tr>
                  <tr>
                    <td>報表流水號</td>
                    <td>
                      {viewData.serialNo}_{i + 1}
                      {serials[j]}
                    </td>
                  </tr>
                  <tr>
                    <td>報表產生日</td>
                    <td>{viewData.reportDate}</td>
                  </tr>
                  <tr>
                    <td>電廠名稱</td>
                    <td>{viewData.pvName}</td>
                  </tr>
                  <tr>
                    <td>電廠專案代號</td>
                    <td>{viewData.projectCode}</td>
                  </tr>
                  <tr>
                    <td>收益權名稱</td>
                    <td>{viewData.solaName}</td>
                  </tr>
                  <tr>
                    <td>收益權代號</td>
                    <td>{viewData.pvId}</td>
                  </tr>
                  <tr>
                    <td className="title">電費單內容</td>
                  </tr>
                  <tr>
                    <td>計費起訖日</td>
                    <td>
                      {viewData.billBeginDate} 至 {viewData.billEndDate}
                    </td>
                  </tr>
                  <tr>
                    <td>計費天數</td>
                    <td>{viewData.billDays}</td>
                  </tr>
                  <tr>
                    <td>計費度數</td>
                    <td>{viewData.billPower}</td>
                  </tr>
                  <tr>
                    <td>總電費(含稅)</td>
                    <td>{viewData.billIncome}</td>
                  </tr>
                  <tr>
                    <td colSpan="3">
                      -----------------------------------------------------------------------------------------------------------------------------------------------
                    </td>
                  </tr>
                  <tr>
                    <td>成本總計</td>
                    <td>{viewData.dispatchToA}</td>
                    <td>
                      <div>電廠營運成本</div>
                      <div>應撥款給{viewData.owner}</div>
                    </td>
                  </tr>
                  <tr>
                    <td>收益總計</td>
                    <td>{viewData.dispatchToB}</td>
                    <td>
                      <div>電廠營運收益</div>
                      <div>應撥款給收益權所屬人</div>
                    </td>
                  </tr>
                  <tr>
                    <td className="title">收益分潤明細</td>
                  </tr>
                  <tr className="field">
                    <td>收益者</td>
                    <td>金額</td>
                    <td>帳戶</td>
                  </tr>
                  <tr>
                    <td>{item.name}</td>
                    <td>{numberFormat({ value: Math.round(item.profit), postfix: '元' })}</td>
                    <td>{item.cashoutAccount}</td>
                  </tr>
                </tbody>
              </table>
              <table className="sign" cellPadding="0" cellSpacing="0">
                <tbody>
                  <tr>
                    <td className="title">報表簽核</td>
                  </tr>
                  <tr className="field">
                    <td>簽核者</td>
                    <td>蓋章</td>
                    <td>日期</td>
                    <td>備註</td>
                  </tr>
                  <tr className="sign">
                    <td>索拉能源股份有限公司</td>
                    <td>(章)</td>
                    <td />
                    <td>報表代理人</td>
                  </tr>
                  <tr className="sign">
                    <td>{viewData.owner}</td>
                    <td>(章)</td>
                    <td />
                    <td>電廠持有人</td>
                  </tr>
                  <tr className="sign">
                    <td>{item.name}</td>
                    <td>(章)</td>
                    <td />
                    <td>收益權持有人</td>
                  </tr>
                </tbody>
              </table>
            </div>
          );
        }
      }
      return (
        <div>
          {distributeReportList}
          {profitReportList}
        </div>
      );
    }
  }
}

export default BillReport;
