import React from 'react';
import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn
} from 'material-ui/Table';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import styled from 'styled-components';
import { numberFormat } from '../Utils/Format';

const TestTable2 = ({ data }) => {
  /*
  const data = [
    ['褒忠雞舍1', '發電中', '253天', '93%', '1,000,000元', '253天', '71,000元', '7.1%', '5,000元'],
    ['褒忠雞舍2', '發電中', '253天', '87%', '2,000,000元', '253天', '125,000元', '6.25%', '10,000元'],
    ['桃園民享', '發電中', '81天', '56%', '3,000,000元', '81天', '120,000元', '4%', '17,000元'],
    ['彰化竹塘', '建置中', '0天', '0%', '1,500,000元', '0天', '0元', '0%', '0元'],
    ['台中啟貿', '建置中', '0天', '0%', '1,600,000元', '0天', '0元', '0%', '0元']
  ];
  */

  const rows = Object.keys(data).map((id, i) => (
    <TableRow key={i}>
      <TableRowColumn>
        <Cell>{data[id].name}</Cell>
      </TableRowColumn>
      <TableRowColumn>
        <Cell>{numberFormat({ value: data[id].investment, postfix: '元' })}</Cell>
      </TableRowColumn>
      <TableRowColumn>
        <Cell>{numberFormat({ value: Math.round(data[id].profit), postfix: '元' })}</Cell>
      </TableRowColumn>
      <TableRowColumn>
        <Cell>{((data[id].profit * 100) / data[id].investment).toFixed(1)} %</Cell>
      </TableRowColumn>
      <TableRowColumn>
        <Cell>{numberFormat({ value: Math.round(data[id].unrealizedProfit), postfix: '元' })}</Cell>
      </TableRowColumn>
      <TableRowColumn>
        <Cell>{((data[id].unrealizedProfit * 100) / data[id].investment).toFixed(1)} %</Cell>
      </TableRowColumn>
    </TableRow>
  ));

  return (
    <div style={{ fontSize: '20px' }}>
      <MuiThemeProvider>
        <Table fixedHeader={false} style={{ tableLayout: 'auto' }}>
          <TableHeader displaySelectAll={false} adjustForCheckbox={false} enableSelectAll={false}>
            <TableRow>
              <TableHeaderColumn>
                <HCell>電廠名稱</HCell>
              </TableHeaderColumn>
              <TableHeaderColumn>
                <HCell>投資金額</HCell>
              </TableHeaderColumn>
              <TableHeaderColumn>
                <HCell>已分配收益</HCell>
              </TableHeaderColumn>
              <TableHeaderColumn>
                <HCell>已分配報酬率</HCell>
              </TableHeaderColumn>
              <TableHeaderColumn>
                <HCell>未分配收益</HCell>
              </TableHeaderColumn>
              <TableHeaderColumn>
                <HCell>未分配報酬率</HCell>
              </TableHeaderColumn>
            </TableRow>
          </TableHeader>
          <TableBody displayRowCheckbox={false} showRowHover={true}>
            {rows}
          </TableBody>
        </Table>
      </MuiThemeProvider>
    </div>
  );
};

const HCell = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: black;
  text-align: right;
`;

const Cell = styled.div`
  font-size: 20px;
  color: grey;
  text-align: right;
`;

export default TestTable2;
